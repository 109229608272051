import {toAbsoluteUrl} from 'src/_metronic/helpers'

const Main = () => {

  const baseUrl = process.env.REACT_APP_URL_ENVIRONMENTS || 'https://bluetradingcorp.com/'

  return (
    <main
      id='main'
      className='bd-masthead text-white landing-index position-relative'
      style={{paddingTop: '6rem', paddingBottom: '8rem'}}
    >
      <video
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          width: '100%',
          left: '50%',
          top: '50%',
          height: '100%',
          objectFit: 'cover',
          transform: 'translate(-50%, -50%)',
          zIndex: '-1',
        }}
        src={toAbsoluteUrl('/media/landing/banner-horizontal.mp4')}
        className='d-none d-sm-block'
      />
      <video
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          width: '100%',
          left: '50%',
          top: '50%',
          height: '100%',
          objectFit: 'cover',
          transform: 'translate(-50%, -50%)',
          zIndex: '-1',
        }}
        src={toAbsoluteUrl('/media/landing/banner-vertical.mp4')}
        className='d-block d-sm-none'
      />
      <div className='bd-masthead pt-5 mt-5'>
        <div className='container-xxl bd-gutter'>
          <div className='col-md-11 mx-auto'>
            <h1 className='mb-3 fw-semibold lh-2 text-white'>
              <span className='sub-h1 '> Haz parte de nuestra</span> <br />
              Selecta comunidad.
            </h1>
            <div className='lead pb-5 col-md-6'>
              <p>
                "Aprovecha las oportunidades que te presenta la vida; cada día es una nueva
                oportunidad para cambiar tu mundo."
              </p>
              Chandra Venkataraman
            </div>
            <div className='flex-row flex-wrap ms-md-auto align-items-start py-5 gap-5 d-flex'>
              <a
                href={`${baseUrl}auth/registration`}
                target='_blank'
                type='button'
                className='col-auto btn btn-primary-l'
                rel='noreferrer'
              >
                REGÍSTRATE
              </a>
              <a
                href='https://t.me/bluetradingcorp'
                target='_blank'
                type='button'
                className='col-auto btn btn-primary-l'
                rel='noreferrer'
              >
                DESCUBRE NUESTRO RENDIMIENTO
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Main
