const TerminosCondicionesPage = () => {
  return (
    <iframe
      style={{height: '100%', width: '100%'}}
      title='terminos-y-condiciones'
      src='https://drive.google.com/file/d/1X3gZ4oBc7y3HwIef8cHzs3ju4hFJESKE/preview'
      allow='autoplay'
    />
  )
}

export {TerminosCondicionesPage}
