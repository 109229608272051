import {useState} from 'react'
import {Alert, Button, Modal} from 'react-bootstrap'
import {sendTransfer} from 'src/services/api/Transfer'

interface IModalAccept {
  modalData: {
    id: number
    transfer: any
    email_transfer: any
    type: string
  }
  onHide: () => void
  getuserDepositsSumVerificado: () => void
  getTransferPendiente: () => void
  show: boolean
  pioneer: number
}

const ModalAccept = ({
  modalData,
  onHide,
  getuserDepositsSumVerificado,
  getTransferPendiente,
  show,
  pioneer,
}: IModalAccept) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<string>('')
  const [error, setError] = useState<string>('')

  const submit = () => {
    setLoading(true)
    sendTransfer(modalData)
      .then((res) => {
        if (res.data) {
          toogleSuccess(res.data)
        } else {
          toogleError(res.error)
        }
      })
      .catch(() => {
        toogleError('Error')
      })
      .finally(() => {
        getTransferPendiente()
      })
  }

  const toogleSuccess = (res: string) => {
    setSuccess(res)
    setTimeout(function () {
      onHide()
      setSuccess('')
    }, 2000)
    setLoading(false)
  }

  const toogleError = (error: string) => {
    setError(error)
    setTimeout(function () {
      setError('')
      onHide()
    }, 2000)
    setLoading(false)
  }

  return (
    <Modal show={show} size='sm' aria-labelledby='contained-modal-title-vcenter' up>
      <Modal.Body className='px-4 text-justify'>
        <span>
          Si desea retirar y su saldo es menor a {pioneer ? '200' : '1000'} USD su cuenta dejara de
          generar profit al no cumplir el mínimo de inversión. <br /> ¿Desea continuar?
        </span>
        {success && (
          <Alert className='mt-4' variant='success'>
            {success}
          </Alert>
        )}
        {error && (
          <Alert className='mt-4' variant='danger'>
            {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!loading && (
          <Button className='me-5' variant='secondary' onClick={() => onHide()}>
            Cancelar
          </Button>
        )}
        {!success && (
          <Button disabled={loading} onClick={() => submit()}>
            {!loading && 'Continuar'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Cargando...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export {ModalAccept}
