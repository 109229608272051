import React from 'react'
import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { MegaMenu } from './MegaMenu'
import { useIntl } from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      {/* <MenuItem title='Layout Builder' to='/builder' /> */}
      <MenuInnerWithSub
        title='Perfil'
        to='/crafted/accounts'
        fontIcon='bi-person'
        hasArrow={true}
        menuPlacement='right-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem to='/crafted/account/overview' title='Ver Perfil' hasBullet={true} />
        <MenuItem to='/crafted/account/settings' title='Configurar Perfil' hasBullet={true} />
      </MenuInnerWithSub>




      {/* <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub> */}
    </>
  )
}
