import {ENVIRONMENTS} from './enviroments'

const getEnvironment = (): string => {
    switch (process.env.REACT_APP_ENV) {
    case 'local':
        return ENVIRONMENTS.LOCAL

    case 'development':
        return ENVIRONMENTS.DEVELOPMENT

    default:
        return ENVIRONMENTS.PRODUCTION
    }
}

export default getEnvironment
