import {Button, Modal} from 'react-bootstrap'
import {toAbsoluteUrl} from 'src/_metronic/helpers'

interface IModalLimit {
  onHide: () => void
  show: boolean
  membershipVencDate: Date
}

const ModalLimit = ({onHide, show, membershipVencDate}: IModalLimit) => {
  const daysVenc = () => {
    const venc = new Date(membershipVencDate).getTime()
    const date = new Date().toISOString().slice(0, 10)
    const dateNow = new Date(date).getTime()
    const daysVenc = Math.floor((venc - dateNow) / (1000 * 60 * 60 * 24)) + 1
    if (daysVenc <= 3) {
      return false
    }
    return true
  }

  return (
    <Modal show={show} size={'lg'} centered>
      <Modal.Body className='text-justify m-6'>
        <span className='svg-icon svg-icon-1'>
          <img
            alt='alerta'
            data-bs-toggle='tooltip'
            className='h-60px'
            src={toAbsoluteUrl(`/media/icons/duotune/alerts/alert-1.png`)}
          />
        </span>
        <p
          className='mt-4'
          style={{color: '#8A8A8A', fontSize: '26px', fontWeight: '500', lineHeight: '104.4%'}}
        >
          Para continuar con la operación{' '}
          <span className='font-italic' style={{color: '#1E85CB'}}>
            lo invitamos a actualizar su licencia
          </span>
          , ya que supera el limite permitido de la actual.
        </p>
        {daysVenc() && (
          <p
            style={{
              color: '#010D26',
              fontSize: '20px',
              fontWeight: '300',
              textAlign: 'justify',
              lineHeight: '104.4%',
            }}
          >
            ¡Esta operación no modificara su fecha de vencimiento del plan por lo que una vez este
            dentro de los 3 días a su vencimiento se habilitará la compra de su nueva licencia!
          </p>
        )}
        <div className='d-flex justify-content-end'>
          <Button
            style={{background: '#4CF3E9', borderRadius: '48px'}}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_upgrade_plan'
            onClick={() => onHide()}
          >
            <span className='mx-2' style={{color: '#0072C1', fontSize: '24px', fontWeight: '700'}}>
              Actualizar
            </span>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export {ModalLimit}
