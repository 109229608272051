import {Steps} from './cards/steps'

const Section1 = () => {
  return (
    <section
      id='section1'
      className='bd-masthead text-white landing-index'
      style={{
        background: 'linear-gradient(180deg, #FFFFFF -45.04%, #CED1D2 146.57%)',
        paddingTop: '6rem',
        paddingBottom: '6rem',
      }}
    >
      <div className='bd-masthead'>
        <div className='container-xxl bd-gutter'>
          <div className='col-md-12 d-flex flex-wrap flex-lg-nowrap gap-5'>
            <div className='d-flex flex-column gap-5 my-auto'>
              <h2 className='text-secondary fw-semibold'>
                Únete en solo
                <br />
                <span className='text-primary'>unos clics</span>
              </h2>
              <div className='col-md-12'>
                <div>
                  <p className='text-gray fs-2'>
                    Sigue estos simples pasos y comienza a construir tu futuro ahora.
                  </p>
                </div>
              </div>
              <a
                href={`https://api.whatsapp.com/send?phone=16162741115`}
                target='_blank'
                rel='noreferrer'
                type='button'
                className='col-auto me-5 btn btn-primary-l'
              >
                ¿TIENES DUDAS? HAZ CLICK AQUÍ
              </a>
            </div>

            <div className='container pt-5 pt-xl-0'>
              <div className='row row-cols-2 row-cols-md-4 g-5'>
                <Steps
                  index={1}
                  img='/media/landing/Step1.gif'
                  title='REGÍSTRATE'
                  text='Completa nuestro formulario de registro fácil y rápido para comenzar tu viaje.'
                />
                <Steps
                  index={2}
                  img='/media/landing/Step2.gif'
                  title='ELIGE TU INVERSIÓN'
                  text='Personaliza tu experiencia. Explora nuestras opciones y elige la que se adapte a tus metas y estilo.'
                />
                <Steps
                  index={3}
                  img='/media/landing/Step3.gif'
                  title='REALIZA EL PAGO'
                  text='Paga de manera segura y eficiente. Con nuestro sistema de pago fácil de usar.'
                />
                <Steps
                  index={4}
                  img='/media/landing/Step4.gif'
                  title='FONDEA TU CUENTA'
                  text='Después del pago, fondea tu cuenta rápidamente y prepárate.'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section1
