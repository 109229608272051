import {toAbsoluteUrl} from 'src/_metronic/helpers'
import {Services} from './cards/services'

const Section2 = () => {
  return (
    <section
      id='section2'
      className='bd-masthead text-white landing-index position-relative'
      style={{paddingTop: '6rem', paddingBottom: '6rem'}}
    >
      <video
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          width: '100%',
          left: '50%',
          top: '50%',
          height: '100%',
          objectFit: 'cover',
          transform: 'translate(-50%, -50%)',
          zIndex: '-1',
        }}
        src={toAbsoluteUrl('/media/landing/banner-horizontal2.mp4')}
        className='d-none d-sm-block'
      />
      <video
        autoPlay
        loop
        muted
        style={{
          position: 'absolute',
          width: '100%',
          left: '50%',
          top: '50%',
          height: '100%',
          objectFit: 'cover',
          transform: 'translate(-50%, -50%)',
          zIndex: '-1',
        }}
        src={toAbsoluteUrl('/media/landing/banner-vertical2.mp4')}
        className='d-block d-sm-none'
      />
      <div className='bd-masthead'>
        <div className='container-xxl bd-gutter'>
          <div className='col-md-12 mx-auto text-center'>
            <h2 className='text-primary fw-semibold pb-2'>Nuestros servicios</h2>
            <p className='col-md-10 mx-auto fs-1 pb-5'>
              Permitimos a nuestros usuarios contacto directo en los intercambios bursátiles
              mediante nuestros sistemas corporativos.
            </p>
            <div className='container pt-5 pt-xl-0 d-none d-sm-block'>
              <div className='row row-cols-3 g-5'>
                <Services
                  img='/media/landing/Service1.gif'
                  size={['165px', '165px']}
                  text={['Depósito directo en cuenta de', 'operaciones.']}
                />
                <Services
                  clasImg='service-img-center'
                  img='/media/landing/blue-connect.svg'
                  size={['165px', '600px']}
                  text={['Conexión con el sistema', 'automatizado de trading.']}
                />
                <Services
                  img='/media/landing/Step4.gif'
                  size={['165px', '165px']}
                  text={['Operación de cuenta master por', 'Traders Especializados.']}
                />
              </div>
            </div>
            <div className='d-flex justify-content-center d-block d-sm-none py-5'>
              <div id='serviceSlide' className='carousel slide' data-bs-ride='carousel'>
                <div className='carousel-inner'>
                  <div className='carousel-item active'>
                    <div>
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/landing/Service1.gif')}
                        className='d-block mx-auto'
                        style={{height: '300px', width: '300px'}}
                      />
                      <p className='text-center fs-1 text-nowrap my-5 py-5'>
                        Depósito directo en cuenta de <br /> operaciones.
                      </p>
                    </div>
                  </div>
                  <div className='carousel-item'>
                    <div>
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/landing/blue-connect.svg')}
                        className='d-block'
                        style={{height: '300px', width: '100%'}}
                      />
                      <p className='text-center fs-1 text-nowrap my-5 py-5'>
                        Conexión con el sistema <br /> automatizado de trading.
                      </p>
                    </div>
                  </div>
                  <div className='carousel-item'>
                    <div>
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/landing/Step4.gif')}
                        className='d-block mx-auto'
                        style={{height: '300px', width: '300px'}}
                      />
                      <p className='text-center fs-1 text-nowrap my-5 py-5'>
                        Operación de cuenta master por <br /> Traders Especializados.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className='col-md-11 mx-auto fs-1 pt-5'>
              Operamos en las plataformas de comercio digital, abarcando Forex, Materias Primas e
              Índices. Además, trabajamos con <span className='fw-bold'>WALLET</span> y{' '}
              <span className='fw-bold'>EXCHANGES</span> para brindarte una experiencia integral y
              versátil.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section2
