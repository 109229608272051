import {useCallback, useEffect, useState} from 'react'
import {KTSVG} from 'src/_metronic//helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {Alert} from 'react-bootstrap'

//Modules
import {UserModel} from 'src/app/modules/auth/models/UserModel'
import {IPendientes} from 'src/models/Pendientes'
//Services
import {userDepositsPendiente, userDepositsSumVerificado} from 'src/services/api/UserDeposits'
import {ModalAccept} from './ModalAccept'
import {PendienteWithdrawal} from './PendienteWithdrawal'
import {sendWithdrawal} from 'src/services/api/Withdrawal'

const Withdrawal = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [loading, setLoading] = useState(false)
  const [deposito, setDeposito] = useState<any>(0)
  const [success, setSuccess] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [pendientes, setPendientes] = useState<IPendientes>()
  // const [saldoPendiente, setSaldoPendiente] = useState<number>(0)
  const [type, setType] = useState('')
  const [saldo, setSaldo] = useState<number>(0)
  const [modalAccept, setModalAccept] = useState(false)
  const [modalData, setModalData] = useState<any>()

  const getPaymentDate = () => {
    const now = new Date(new Date().toLocaleString('en-US', {timeZone: 'America/Bogota'}))
    const currentHour = now.getHours()
    const currentDay = now.getDay()
    let paymentDate = new Date(now)
    let daysToAdd = 0

    if (currentDay === 0) {
      daysToAdd = 3
    }
    if (currentDay === 1) {
      daysToAdd = 2
    }
    if (currentDay === 2) {
      daysToAdd = currentHour >= 18 ? 3 : 2
    }
    if (currentDay === 3) {
      daysToAdd = 2
    }
    if (currentDay === 4) {
      daysToAdd = currentHour >= 18 ? 6 : 1
    }
    if (currentDay === 5) {
      daysToAdd = 5
    }
    if (currentDay === 6) {
      daysToAdd = 4
    }
    paymentDate.setDate(now.getDate() + daysToAdd)

    paymentDate.setHours(14, 0, 0, 0)
    return paymentDate.toLocaleDateString('es-ES', {
      weekday: 'long',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
  }

  const getUserDepositPendiente = useCallback(() => {
    userDepositsPendiente(user.id).then((data) => {
      setPendientes(data)
      // setSaldoPendiente(data.sum)
    })
    userDepositsSumVerificado(user.id)
      .then((res) => {
        setDeposito(res)
      })
      .catch(() => {})
  }, [user.id])

  useEffect(() => {
    getUserDepositPendiente()
  }, [getUserDepositPendiente])

  const WithdrawalSchema = Yup.object().shape({
    retiro: Yup.number()
      .min(1, 'Ingrese retiro valido')
      .max(saldo, `Maximo ${saldo} para retirar`)
      .required('El retiro es requerido'),
  })

  const initialValues = {
    retiro: '0',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: WithdrawalSchema,
    onSubmit: async (values: any, {resetForm}) => {
      setLoading(true)
      const params = {
        id: user.id,
        email: user.email,
        retiro: values.retiro,
        type: type,
      }
      if (type === 'RETIRO') {
        if (user.pioneer) {
          if (deposito.saldo - values.retiro < 200) {
            setModalData(params)
            setModalAccept(true)
            setLoading(false)
          } else {
            sendWithdrawal(params)
              .then((res) => {
                if (res.data) {
                  toogleSuccess(res.data)
                  getUserDepositPendiente()
                } else {
                  toogleError(res.error)
                }
              })
              .catch(() => {
                toogleError('Error')
              })
          }
        } else {
          if (deposito.saldo - values.retiro < 1000) {
            setModalData(params)
            setModalAccept(true)
            setLoading(false)
          } else {
            sendWithdrawal(params)
              .then((res) => {
                if (res.data) {
                  toogleSuccess(res.data)
                  getUserDepositPendiente()
                } else {
                  toogleError(res.error)
                }
              })
              .catch(() => {
                toogleError('Error')
              })
          }
        }
      } else {
        sendWithdrawal(params)
          .then((res) => {
            if (res.data) {
              toogleSuccess(res.data)
              getUserDepositPendiente()
            } else {
              toogleError(res.error)
            }
          })
          .catch(() => {
            toogleError('Error')
          })
      }
    },
  })

  const toogleSuccess = (res: string) => {
    setSuccess(res)
    setTimeout(function () {
      setSuccess('')
    }, 4000)
    formik.setFieldValue('retiro', 0)
    setLoading(false)
  }

  const toogleError = (error: string) => {
    setError(error)
    setTimeout(function () {
      setError('')
    }, 4000)
    setLoading(false)
  }

  const changeSaldo = (e: string) => {
    if (e === 'RETIRO') {
      setSaldo(deposito.saldo)
    } else {
      setSaldo(deposito.licencia)
    }
  }

  return (
    <div className='modal fade' id='kt_modal_withdrawal' aria-hidden='true'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body pt-0 pb-15 px-5 px-md-15'>
            <h1 className='fs-2qx fw-boldest text-center'>Retirar de tu cuenta</h1>
            <div id='kt_account_profile_details'>
              <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='card-body'>
                  <div className='card fw-bold my-6'>
                    <div className='row mb-6 d-flex justify-content-center'>
                      <div className='col-lg-4 col-form-label fw-bold fs-6'>
                        <label className='text-primary fs-2 fw-bolder'>
                          Tipo de retiro:<sup>*</sup>
                        </label>
                      </div>
                      <div className='col-lg-6 fv-row me-max'>
                        <select
                          className='form-select form-select-border'
                          role='button'
                          onChange={(e) => {
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-sequences
                            setType(e.target.value), changeSaldo(e.target.value)
                          }}
                        >
                          <option value=''>Selecciona...</option>
                          <option value='RETIRO'>Saldo aprobado</option>
                          <option value='RLICENCIA'>Beneficio de licencias</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {type && (
                    <div className='card fw-bold my-6'>
                      <div className='row mb-6 d-flex justify-content-center'>
                        <div className='col-lg-4 col-form-label fw-bold fs-6'>
                          <label className='transfer-text-select'>
                            Monto a retirar:<sup>*</sup>
                          </label>
                        </div>
                        <div className='col-lg-6 fv-row me-max'>
                          <input
                            type='number'
                            className='form-control '
                            placeholder=''
                            min={1}
                            step='1'
                            {...formik.getFieldProps('retiro')}
                            onChange={(e) => {
                              const value = Math.floor(Number(e.target.value))
                              formik.setFieldValue('retiro', value)
                            }}
                          />
                          {formik.touched.retiro && formik.errors.retiro && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.retiro}</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='row mb-6 d-flex justify-content-center'>
                        <div className='col-lg-4 col-form-label fw-bold fs-6'>
                          <label className='transfer-text-select'>Nuevo saldo:</label>
                        </div>
                        <div className='col-lg-6 fv-row me-max'>
                          <div className='form-control form-control-transparent'>
                            {deposito && type === 'RETIRO'
                              ? (deposito.saldo - formik.getFieldProps('retiro').value).toFixed(2)
                              : deposito.licencia - formik.getFieldProps('retiro').value}
                          </div>
                        </div>
                      </div>
                      {formik.values.retiro > 0 && (
                        <div className='col mb-6 fw-normal'>
                          Esta transacción se efectuara el{' '}
                          <span className='fw-bolder'>próximo {getPaymentDate()}</span>. entre las
                          02:00pm y 10:00pm GMT -5.
                        </div>
                      )}
                      {success && <Alert variant='success'>{success}</Alert>}
                      {error && <Alert variant='danger'>{error}</Alert>}
                      <div className='card-footer d-flex flex-center flex-row-fluid pt-12'>
                        <button
                          type='reset'
                          className='btn transfer-button-cancel me-3'
                          data-bs-dismiss='modal'
                        >
                          Cancelar
                        </button>
                        <button type='submit' className='btn transfer-button' disabled={loading}>
                          {!loading && 'Procesar'}
                          {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Cargando...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
            {pendientes && <PendienteWithdrawal pendientes={pendientes} />}
          </div>
        </div>
      </div>

      <ModalAccept
        modalData={modalData}
        getUserDepositPendiente={getUserDepositPendiente}
        show={modalAccept}
        onHide={() => setModalAccept(false)}
        pioneer={user.pioneer}
      />
    </div>
  )
}

export {Withdrawal}
