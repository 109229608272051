import {useIntl} from 'react-intl'
import {PageTitle} from 'src/_metronic/layout/core'
import {TablesWidget5} from 'src/_metronic/partials/widgets'
import {Modal} from 'react-bootstrap'
import {UserModel} from 'src/app/modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {userDepositsSumVerificado} from 'src/services/api/UserDeposits'
import {useEffect, useState} from 'react'

//Services
import {getLicense} from 'src/services/api/License'
import TuSaldoHorizontal from 'src/app/modules/widgets/components/TuSaldoHorizontal'
import {toAbsoluteUrl} from 'src/_metronic/helpers'

interface Iinit {
  setIni: (value: boolean) => void
  ini: boolean
  setVenc: (value: boolean) => void
  venc: boolean
}

const DashboardWrapper = ({setIni, ini, setVenc, venc}: Iinit) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const intl = useIntl()
  const [deposito, setDeposito] = useState<any>()
  const [license, setLicenses] = useState<string | null>()
  const [loading, setLoading] = useState(true)
  const isMobile = window.innerWidth <= 768
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null)
  const [, setIsPWAInstalled] = useState(false)

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault()
      setDeferredPrompt(e)
    }

    window.addEventListener('beforeinstallprompt', handler)

    // Check if PWA is installed
    const isStandalone =
      window.matchMedia('(display-mode: standalone)').matches || (navigator as any).standalone
    setIsPWAInstalled(isStandalone)

    return () => window.removeEventListener('beforeinstallprompt', handler)
  }, [])

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt()
      const choiceResult = await deferredPrompt.userChoice
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt')
      } else {
        console.log('User dismissed the install prompt')
      }
      setDeferredPrompt(null)
    }
  }

  useEffect(() => {
    userDepositsSumVerificado(user.id)
      .then((res) => {
        if (user.pioneer) {
          setDeposito(res.saldo < 200)
        } else {
          setDeposito(res.saldo < 1000)
        }

        getLicense(user.id)
          .then((lic) => {
            setLicenses(lic.vencimiento)
            setLoading(false)
          })
          .catch(() => {
            setLicenses(null)
            setLoading(false)
          })
      })
      .catch(() => {
        setLoading(false)
      })
  }, [user])

  const diasVencimiento = () => {
    let difference = 0
    if (user) {
      const ven = new Date(user.membershipVencDate).getTime()
      const now = new Date().toISOString().slice(0, 10)
      const today = new Date(now).getTime()
      difference = Math.floor((ven - today) / (1000 * 60 * 60 * 24))
      difference += 1
    }
    return difference
  }

  const getModal = () => {
    if (deposito && !license) {
      return (
        <Modal show={ini} onHide={() => setIni(false)} size='lg'>
          <Modal.Body>
            <div className='d-flex align-items-center p-0'>
              <img
                alt='alerta'
                data-bs-toggle='tooltip'
                className='alerts-img me-5'
                src={toAbsoluteUrl(`/media/icons/duotune/alerts/alert-2.png`)}
              />
              <p className='m-0 p-0 alerts-font-24'>
                No cuentas con una licencia vigente ni con saldo disponible,{' '}
                <span style={{color: '#1E85CB'}}>
                  te invitamos a depositar y adquirir una licencia para disfrutar de nuestros
                  beneficios.
                </span>
              </p>
            </div>
          </Modal.Body>
        </Modal>
      )
    } else if (!license) {
      return (
        <Modal show={ini} onHide={() => setIni(false)} size='lg'>
          <Modal.Body>
            <div className='d-flex align-items-center p-0'>
              <img
                alt='alerta'
                data-bs-toggle='tooltip'
                className='alerts-img me-5'
                src={toAbsoluteUrl(`/media/icons/duotune/alerts/alert-2.png`)}
              />
              <p className='m-0 p-0 alerts-font-24'>
                No cuentas con una licencia vigente,{' '}
                <span style={{color: '#1E85CB'}}>
                  te invitamos a adquirir una licencia para disfrutar de nuestros beneficios
                </span>
              </p>
            </div>
          </Modal.Body>
        </Modal>
      )
    } else if (deposito) {
      return (
        <Modal show={ini} onHide={() => setIni(false)} size='lg'>
          <Modal.Body>
            <div className='d-flex align-items-center p-0'>
              <img
                alt='alerta'
                data-bs-toggle='tooltip'
                className='alerts-img me-5'
                src={toAbsoluteUrl(`/media/icons/duotune/alerts/alert-2.png`)}
              />
              <p className='m-0 p-0 alerts-font-24'>
                Usuario de Blue Trading Corp, no cuenta con el saldo mínimo de{' '}
                {user.pioneer ? '200' : '1000'} usd para aplicar profit,{' '}
                <span style={{color: '#1E85CB'}}>
                  lo invitamos a completar el restante para poder disfrutar del beneficio.
                </span>
              </p>
            </div>
          </Modal.Body>
        </Modal>
      )
    }
    return ''
  }

  return (
    <>
      {!loading && getModal()}

      {user && user.membership_renew !== 0 && diasVencimiento() <= 7 && diasVencimiento() > 0 && (
        <Modal show={venc} onHide={() => setVenc(false)} size='lg'>
          <Modal.Body>
            <div className='d-flex align-items-center p-0'>
              <img
                alt='alerta'
                data-bs-toggle='tooltip'
                className='alerts-img me-5'
                src={toAbsoluteUrl(`/media/icons/duotune/alerts/alert-2.png`)}
              />
              <p className='m-0 p-0 alerts-font-24'>
                Usuario Blue su licencia se va a terminar en {diasVencimiento()} días,{' '}
                <span style={{color: '#1E85CB'}}>
                  pero te la renovaremos automáticamente a la que corresponda su saldo actual.
                </span>
              </p>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {isMobile && (
        <Modal
          show={venc}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          id='pwaModal'
          name='pwaModal'
          onHide={() => setVenc(false)}
          className='shadow-none'
        >
          <Modal.Body
            className='p-0 b-0 position-relative mx-5 d-flex justify-content-center'
            style={{maxHeight: '80vh', width: 'fit-content'}}
          >
            <div>
              <img
                className='img-fluid d-sm-none'
                src='media/illustrations/pwa_sm.webp'
                alt='banner'
                style={{width: '80vw'}}
              />
            </div>
            <div className='position-absolute bottom-0 start-50 translate-middle-x pb-5 pb-md-2 pb-lg-5 d-flex justify-content-center gap-5 w-100'>
              <img
                className='img-fluid d-sm-none mb-3'
                src='media/illustrations/pwa_button.webp'
                alt='banner'
                style={{width: '60%'}}
                onClick={handleInstallClick}
              />
            </div>
            <div className='position-absolute top-0 end-0 mt-5 me-5'>
              <button
                type='button'
                className='btn-close btn-close-white fs-1'
                aria-label='Close'
                onClick={() => setVenc(false)}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}

      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className='container '>
        <TuSaldoHorizontal />
        <TablesWidget5 className='' />
      </div>
    </>
  )
}

export {DashboardWrapper}
