import {toAbsoluteUrl} from 'src/_metronic/helpers'

const HeaderNotificationsMenu = () => (
  <div className='menu menu-sub menu-sub-dropdown tools tools-title-radius' data-kt-menu='true'>
    <div>
      <div className='d-flex flex-column tools-header' style={{background: '#0072C1'}}>
        <div className='d-flex align-items-center my-10 px-10'>
          <div className='symbol symbol-circle symbol-50px me-5'>
            <img alt='tools' src={toAbsoluteUrl('/media/icons/duotune/general/tools.png')} />
          </div>
          <h3 className='tools-title m-0 p-0'>Herramientas</h3>
        </div>

        <ul className='nav nav-line-tabs px-10 tools-sub-title d-flex justify-content-between'>
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4 active'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_1'
              translate='no'
            >
              Planes
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_2'
            >
              Depositos
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_3'
            >
              Retiros
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link text-white opacity-75 opacity-state-100 pb-4'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_4'
            >
              Transferencias
            </a>
          </li>
        </ul>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='d-flex flex-column px-9'>
            <div className='pt-10 pb-0'>
              <h3 className='tools-content-title'>Activa tu cuenta</h3>

              <div className='tools-content-text'>
                En BlueTradingCorp contamos con planes acordes a tu capacidad de negociación.
              </div>

              <div className='text-center mt-5 mb-9'>
                <button
                  type='button'
                  className='fs-1 btn btn-primary fw-bolder rounded-pill py-2'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_upgrade_plan'
                  translate='no'
                >
                  Adquirir plan
                </button>
              </div>
            </div>

            <div className='text-center'>
              <img
                className='w-200px'
                alt='metronic'
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/tools.png')}
              />
            </div>
          </div>
        </div>

        <div className='tab-pane fade show' id='kt_topbar_notifications_2' role='tabpanel'>
          <div className='d-flex flex-column px-9'>
            <div className='pt-10 pb-0'>
              <h3 className='tools-content-title'>Deposita a tu cuenta</h3>

              <div className='tools-content-text'>
                En BlueTrading Corp contamos con opciones de negocios estratégicas, capaces de
                hacerte alcanzar tus metas y hacerlas realidad.
              </div>

              <div className='text-center mt-5 mb-9'>
                <button
                  type='button'
                  className='fs-1 btn btn-primary fw-bolder rounded-pill py-2'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_deposits'
                >
                  Depositar
                </button>
              </div>
            </div>

            <div className='text-center'>
              <img
                className='w-200px'
                alt='metronic'
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/tools.png')}
              />
            </div>
          </div>
        </div>

        <div className='tab-pane fade show' id='kt_topbar_notifications_3' role='tabpanel'>
          <div className='d-flex flex-column px-9'>
            <div className='pt-10 pb-0'>
              <h3 className='tools-content-title'>Retira de tu cuenta</h3>

              <div className='tools-content-text'>
                En <span translate='no'>blue trading</span> puedes retirar tu saldo disponible en
                cualquier momento.
              </div>

              <div className='text-center mt-5 mb-9'>
                <button
                  type='button'
                  className='fs-1 btn btn-primary fw-bolder rounded-pill py-2'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_withdrawal'
                >
                  Retirar
                </button>
              </div>
            </div>

            <div className='text-center'>
              <img
                className='w-200px'
                alt='metronic'
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/tools.png')}
              />
            </div>
          </div>
        </div>

        <div className='tab-pane fade show' id='kt_topbar_notifications_4' role='tabpanel'>
          <div className='d-flex flex-column px-9'>
            <div className='pt-10 pb-0'>
              <h3 className='tools-content-title'>Transfiere a usuarios blue</h3>

              <div className='tools-content-text'>
                En blue tenemos opciones ilimitadas para que transfieras a otros usuarios blue por
                solo $2 USD.
              </div>

              <div className='text-center mt-5 mb-9'>
                <button
                  type='button'
                  className='fs-1 btn btn-primary fw-bolder rounded-pill py-2'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_transfer'
                >
                  Transferir
                </button>
              </div>
            </div>

            <div className='text-center'>
              <img
                className='w-200px'
                alt='metronic'
                src={toAbsoluteUrl('/media/illustrations/sketchy-1/tools.png')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default HeaderNotificationsMenu
