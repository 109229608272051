/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {register} from '../redux/AuthCRUD'
import {Link, useLocation} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {toAbsoluteUrl} from 'src/_metronic/helpers'

const registrationSchema = Yup.object().shape({
  nick: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('El Nick es requerido'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('El correo es requerido'),
  email_confirmation: Yup.string()
    .required('El correo de confirmacion es requerido')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('email')],
        'Correo electrónico y Confirmar correo electrónico no coinciden'
      ),
    }),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Se requiere contraseña'),
  password_confirmation: Yup.string()
    .required('Se requiere confirmación de contraseña')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Contraseña y Confirmar contraseña no coinciden'
      ),
    }),
  acceptTerms: Yup.bool().required('Debe aceptar los Términos y Condiciones'),
  acceptPriv: Yup.bool().required('Debe aceptar la Politica de Privacidad'),
})

// eslint-disable-next-line no-empty-pattern
export function Registration({}) {
  let query = useQuery()
  let referal
  if (!query.get('ref')) {
    referal = ''
  } else {
    referal = query.get('ref')
  }

  const initialValues = {
    nick: '',
    referred_by: query.get('ref'),
    email: '',
    email_confirmation: '',
    password: '',
    password_confirmation: '',
    acceptTerms: false,
    acceptPriv: false,
  }
  const [loading, setLoading] = useState(false)
  const [tyc, setTyC] = useState(false)
  const [pyp, setPyP] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        register(
          values.nick,
          values.referred_by,
          values.email,
          values.email_confirmation,
          values.password,
          values.password_confirmation
        )
          .then(({data: {api_token}}) => {
            setLoading(false)
            dispatch(auth.actions.register(api_token))
          })
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus('El Proceso de registro fallo')
          })
      }, 1000)
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const ChangeTyC = () => {
    const vartyc = document.getElementById('kt_login_tyc_agree') as HTMLInputElement | null
    if (vartyc) {
      vartyc.checked = true
      formik.setFieldValue('kt_login_tyc_agree', true)
      setTyC(true)
    }
  }
  const ChangePyP = () => {
    const varpyp = document.getElementById('kt_login_priv_agree') as HTMLInputElement | null
    if (varpyp) {
      varpyp.checked = true
      formik.setFieldValue('kt_login_priv_agree', true)
      setPyP(true)
    }
  }
  const ToogleTyC = () => {
    const vartyc = document.getElementById('kt_login_tyc_agree') as HTMLInputElement | null
    if (vartyc) {
      vartyc.checked = !tyc
      setTyC(!tyc)
    }
  }
  const TooglePyP = () => {
    const varpyp = document.getElementById('kt_login_priv_agree') as HTMLInputElement | null
    if (varpyp) {
      varpyp.checked = !pyp
      setPyP(!pyp)
    }
  }
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='mb-10 text-center'>
        <h1 className='text-dark mb-3'>Crear Cuenta</h1>

        <div className='text-gray-400 fw-bold fs-4'>
          Ya tienes una Cuenta?
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Inicia
          </Link>
        </div>
      </div>

      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Ingresa con google
      </button> */}

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>O</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='fv-row mb-7'>
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Nickname</label>
          <input
            placeholder='Nickname'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('nick')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.nick && formik.errors.nick,
              },
              {
                'is-valid': formik.touched.nick && !formik.errors.nick,
              }
            )}
          />
          {formik.touched.nick && formik.errors.nick && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.nick}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Correo</label>
        <input
          placeholder='Correo'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirmar Correo</label>
        <input
          type='email'
          placeholder='Confirmar Correo'
          autoComplete='off'
          {...formik.getFieldProps('email_confirmation')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.email_confirmation && formik.errors.email_confirmation,
            },
            {
              'is-valid': formik.touched.email_confirmation && !formik.errors.email_confirmation,
            }
          )}
        />
        {formik.touched.email_confirmation && formik.errors.email_confirmation && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email_confirmation}</span>
            </div>
          </div>
        )}
      </div>

      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Contraseña</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Contraseña'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
        </div>
        <div className='text-muted'>
          Use 8 o más caracteres con una combinación de letras, números y símbolos.
        </div>
      </div>

      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirmar Contraseña</label>
        <input
          type='password'
          placeholder='Confirmar Contraseña'
          autoComplete='off'
          {...formik.getFieldProps('password_confirmation')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid':
                formik.touched.password_confirmation && formik.errors.password_confirmation,
            },
            {
              'is-valid':
                formik.touched.password_confirmation && !formik.errors.password_confirmation,
            }
          )}
        />
        {formik.touched.password_confirmation && formik.errors.password_confirmation && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password_confirmation}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-10'>
        <div className='mb-2 form-check form-check-custom form-check-solid'>
          <input
            onClick={() => ToogleTyC()}
            className='form-check-input'
            type='checkbox'
            id='kt_login_tyc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_tyc_agree'
          >
            <a
              onClick={() => ChangeTyC()}
              style={{color: 'inherit', textDecoration: 'underline'}}
              target={'_blank'}
              href={toAbsoluteUrl('/terminos-y-condiciones')}
              rel='noreferrer'
            >
              Acepto los términos y condiciones.
            </a>
          </label>

          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            onClick={() => TooglePyP()}
            className='form-check-input'
            type='checkbox'
            id='kt_login_priv_agree'
            {...formik.getFieldProps('acceptPriv')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_priv_agree'
          >
            <a
              onClick={() => ChangePyP()}
              style={{color: 'inherit', textDecoration: 'underline'}}
              target={'_blank'}
              href={toAbsoluteUrl('/politica-de-privacidad')}
              rel='noreferrer'
            >
              Acepto la política de privacidad.
            </a>
          </label>
          {formik.touched.acceptPriv && formik.errors.acceptPriv && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptPriv}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !tyc || !pyp}
        >
          {!loading && <span className='indicator-label'>Registarse</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Espere un momento...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancelar
          </button>
        </Link>
      </div>
    </form>
  )
}
function useQuery() {
  const {search} = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}
