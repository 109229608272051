export interface IWallets {
  name: string
  value: string
  image: string
}

const Wallets: IWallets[] = [
  {
    name: 'Red TRC20 (USDT)',
    value: 'TDEVtYHMi4MV9E8uxUm9zDXeMiBMRkv29u',
    image: '/media/deposit/DepositPayQr.png',
  },
  {
    name: 'Binance Pay',
    value: '243059804',
    image: '/media/binance/BinancePayQR.png',
  },
]

export default Wallets
