import {useCallback, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {Alert} from 'react-bootstrap'

//Modules
import {UserModel} from 'src/app/modules/auth/models/UserModel'
import PioneerSub from '../../upgrade-plan/model/Subscription'
import NormalSub from '../../upgrade-plan/model/Subscription2'

//Services
import {sendTransfer} from 'src/services/api/Transfer'
import {userDepositsSumVerificado} from 'src/services/api/UserDeposits'
import {ModalAccept} from './ModalAccept'
import {ModalLimit} from '../../deposits/ModalLimit'

interface propsData {
  getTransferPendiente: () => void
}

const TransferSend = ({getTransferPendiente}: propsData) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [deposito, setDeposito] = useState<any>(0)
  const [type, setType] = useState('')
  const [modalAccept, setModalAccept] = useState(false)
  const [modalData, setModalData] = useState<any>()
  const [modalLimit, setModalLimit] = useState(false)

  let Subscription = NormalSub
  if (user.pioneer) {
    Subscription = PioneerSub
  }

  const getuserDepositsSumVerificado = useCallback(() => {
    userDepositsSumVerificado(user.id)
      .then((res) => {
        setDeposito(res)
      })
      .catch(() => {})
  }, [user.id])

  useEffect(() => {
    getuserDepositsSumVerificado()
  }, [getuserDepositsSumVerificado])

  const TransferSchema = Yup.object().shape({
    transfer:
      type === 'RETIRO'
        ? Yup.number()
            .min(1, 'Ingrese retiro valido')
            .max((deposito.saldo ?? 0) - 2, `Maximo ${(deposito.saldo ?? 0) - 2} para transferir`)
            .required('El retiro es requerido')
        : Yup.number()
            .min(1, 'Ingrese retiro valido')
            .max(deposito.licencia ?? 0, `Maximo ${deposito.licencia ?? 0} para transferir`)
            .required('El saldo es requerido'),
    email:
      type === 'RETIRO'
        ? Yup.string().email('Formato de correo incorrecto').required('El Correo es requerido')
        : Yup.string(),
  })

  const initialValues = {
    retiro: '0',
    email: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: TransferSchema,
    onSubmit: async (values: any, {resetForm}) => {
      setLoading(true)
      const params = {
        id: user.id,
        transfer: values.transfer,
        email_transfer: values.email,
        type: type,
      }
      setModalData(params)
      if (type === 'RETIRO') {
        if (user.pioneer) {
          if (deposito.saldo - values.transfer < 200) {
            setModalAccept(true)
            setLoading(false)
          } else {
            submit(params)
          }
        } else {
          if (deposito.saldo - values.transfer < 1000) {
            setModalAccept(true)
            setLoading(false)
          } else {
            submit(params)
          }
        }
      } else {
        if (limiteDeposito(params.transfer)) {
          setModalLimit(true)
          toogleSuccess('')
        } else {
          submit(params)
        }
      }
    },
  })

  const submit = (params: any) => {
    sendTransfer(params)
      .then((res) => {
        if (res.data) {
          toogleSuccess(res.data)
        } else {
          toogleError(res.error)
        }
      })
      .catch(() => {
        toogleError('Error')
      })
      .finally(() => {
        formik.setFieldValue('transfer', '')
        formik.setFieldValue('email', '')
        getuserDepositsSumVerificado()
        getTransferPendiente()
      })
  }

  const toogleSuccess = (res: string) => {
    setSuccess(res)
    setTimeout(function () {
      setSuccess('')
    }, 10000)
    setLoading(false)
  }

  const toogleError = (error: string) => {
    setError(error)
    setTimeout(function () {
      setError('')
    }, 4000)
    setLoading(false)
  }

  const limiteDeposito = (deposit: string) => {
    if (user.membership === 'B150' || user.membership === 'A300') {
      return false
    }

    let amount = Subscription.find(
      (e) => e.package === user.membership || e.packageAnnual === user.membership
    )
    if (!amount || deposito.saldo + parseFloat(deposit) > amount.limitUpper) {
      return true
    }
    return false
  }

  return (
    <div className='modal-body pt-0 pb-15 px-5 px-md-15'>
      <h1 className='fs-2qx fw-boldest text-center'>Transfiere de tu cuenta</h1>
      <div id='kt_account_profile_details'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body'>
            <div className='card fw-bold my-6'>
              <div className='row mb-6 d-flex justify-content-center'>
                <div className='col-lg-5 col-form-label fw-bold fs-6'>
                  <label className='text-primary fs-2 fw-bolder'>
                    Tipo de retiro:<sup>*</sup>
                  </label>
                </div>
                <div className='col-lg-6 fv-row ps-0'>
                  <select
                    className='form-select form-select-border'
                    role='button'
                    onChange={(e) => {
                      setType(e.target.value)
                    }}
                  >
                    <option value='' selected disabled>
                      Selecciona...
                    </option>
                    <option value='RETIRO'>Saldo aprobado</option>
                    <option value='RLICENCIA'>Beneficio de licencias</option>
                  </select>
                </div>
              </div>
            </div>
            {type && (
              <div className='card fw-bold my-6'>
                <div className='row mb-6 d-flex justify-content-center'>
                  <div className='col-lg-5 col-form-label fw-bold fs-6'>
                    <label className='text-primary fs-2 fw-bolder'>
                      {type === 'RETIRO' ? 'Saldo Total:' : 'Saldo Ganancia Por Licencia:'}
                    </label>
                  </div>
                  <div className='col-lg-6 fv-row ps-0'>
                    <div className='form-control form-control-transparent'>
                      {type === 'RETIRO' ? deposito.saldo : deposito.licencia}
                    </div>
                  </div>
                </div>
                <div className='row mb-6 d-flex justify-content-center'>
                  <div className='col-lg-5 col-form-label fw-bold fs-6'>
                    <label className='text-primary fs-2 fw-bolder'>
                      {type === 'RETIRO' ? 'Monto a transferir' : 'Transferir a mi saldo'}:{' '}
                      <sup>*</sup>
                    </label>
                  </div>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='number'
                      className='form-control form-control-lg transfer-select'
                      placeholder=''
                      min={1}
                      {...formik.getFieldProps('transfer')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                    />
                    {formik.touched.transfer && formik.errors.transfer && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.transfer}</div>
                      </div>
                    )}
                  </div>
                </div>
                {type === 'RETIRO' ? (
                  <>
                    <div className='row mb-6 d-flex justify-content-center'>
                      <div className='col-lg-5 col-form-label fw-bold fs-6'>
                        <label className='text-primary fs-2 fw-bolder'>
                          Correo a transferir: <sup>*</sup>
                        </label>
                      </div>

                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          className='form-control form-control-lg transfer-select'
                          placeholder=''
                          {...formik.getFieldProps('email')}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.email}</div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='my-5'>
                      La transferencia de mi saldo a otro usuario Blue Trading Corp{' '}
                      <span className='text-primary'>tendrá un cargo de 2 USD</span> que serán
                      descontados del monto a transferir.
                    </div>
                  </>
                ) : (
                  <div className='my-5'>
                    Con esta operación{' '}
                    <span className='text-primary'>
                      transferirá tu beneficio obtenido por licencia
                    </span>{' '}
                    a tu saldo aprobado.
                  </div>
                )}

                {success && (
                  <Alert className='mt-4' variant='success'>
                    {success}
                  </Alert>
                )}
                {error && (
                  <Alert className='mt-4' variant='danger'>
                    {error}
                  </Alert>
                )}
                <div className='card-footer d-flex flex-center flex-row-fluid pt-12'>
                  <button
                    type='reset'
                    className='btn transfer-button-cancel me-3'
                    data-bs-dismiss='modal'
                  >
                    Cancelar
                  </button>
                  {!loading && (
                    <button type='submit' className='btn transfer-button'>
                      Procesar
                    </button>
                  )}
                  {loading && (
                    <button className='btn transfer-button'>
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Cargando...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
      <ModalAccept
        modalData={modalData}
        getuserDepositsSumVerificado={getuserDepositsSumVerificado}
        getTransferPendiente={getTransferPendiente}
        show={modalAccept}
        onHide={() => setModalAccept(false)}
        pioneer={user.pioneer}
      />
      <ModalLimit
        show={modalLimit}
        onHide={() => setModalLimit(false)}
        membershipVencDate={user.membershipVencDate}
      />
    </div>
  )
}

export default TransferSend
