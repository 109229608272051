import clsx from 'clsx'
import {toAbsoluteUrl} from 'src/_metronic/helpers'
import {SupportNotificationsMenu} from 'src/_metronic/partials/layout/support/SupportNotificationsMenu'

const Support = () => {
  const width = window.innerWidth

  return (
    <div className='menu-item'>
      {width > 768 ? (
        <div
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='left'
          data-kt-menu-flip='left'
        >
          <div className={clsx('menu-link without-sub')}>
            <div className='symbol'>
              <img
                alt='Ayuda'
                src={toAbsoluteUrl('/media/icons/duotune/communication/support.png')}
                className='w-90px h-90px'
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='top'
          data-kt-menu-flip='left'
        >
          <div className={clsx('menu-link without-sub')}>
            <div className='symbol'>
              <img
                alt='Ayuda'
                src={toAbsoluteUrl('/media/icons/duotune/communication/support.png')}
                className='w-90px h-90px'
              />
            </div>
          </div>
        </div>
      )}
      <SupportNotificationsMenu />
    </div>
  )
}

export {Support}
