import axios, { AxiosResponse } from 'axios';
import { ISupport } from 'src/models/Support';
import { API_ENDPOINTS, getEndPoint } from './utils/endpoints';

export const SendSupport = async (params: ISupport): Promise<string> => {
    const url: string = getEndPoint(API_ENDPOINTS.SUPPORT.SEND_SUPPORT);

    try {
        const api_reponse: AxiosResponse = await axios.post(url, { ...params });
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};


export const SendMailSupport = async (params: any): Promise<string> => {
    const url: string = getEndPoint(API_ENDPOINTS.SUPPORT.EMAIL);

    try {
        const api_reponse: AxiosResponse = await axios.post(url, { ...params });
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};