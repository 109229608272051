import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import HeaderNotificationsMenu from 'src/_metronic/partials/layout/header-menus/HeaderNotificationsMenu'

const Transactions = () => {
  const width = window.innerWidth

  return (
    <div className='menu-item'>
      {width > 768 ? (
        <div
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='left-start'
          data-kt-menu-flip='left'
        >
          <div className={clsx('menu-link without-sub')}>
            <span className='menu-icon'>
              <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-2' />
            </span>
            <span className='menu-title'>Transacciones</span>
          </div>
        </div>
      ) : (
        <div
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-start'
          data-kt-menu-flip='right'
        >
          <div className={clsx('menu-link without-sub')}>
            <span className='menu-icon'>
              <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-2' />
            </span>
            <span className='menu-title'>Transacciones</span>
          </div>
        </div>
      )}
      <HeaderNotificationsMenu />
    </div>
  )
}

export {Transactions}
