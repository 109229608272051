import {toAbsoluteUrl} from 'src/_metronic/helpers'

const Footer = () => {
  const baseUrl = process.env.REACT_APP_URL_ENVIRONMENTS || 'https://bluetradingcorp.com/'

  return (
    <section
      className='bd-masthead text-white landing-index py-5'
      style={{
        background: 'linear-gradient(180deg, #FFFFFF -45.04%, #CED1D2 146.57%)',
      }}
    >
      <div className='bd-masthead'>
        <div className='container-xxl bd-gutter d-flex justify-content-between align-items-center flex-wrap flex-lg-nowrap gap-2'>
          <div className='p-0 me-2 d-none d-lg-block'>
            <img alt='Logo' src={toAbsoluteUrl('/media/landing/logo.svg')} className='h-45px' />
          </div>
          <div className='fs-7 text-gray text-nowrap d-flex justify-content-center w-100 fs-footer-mobile'>
            © 2021 - 2024 BLUE TRADING | ALL RIGHTS RESERVED | DESARROLLADA POR EPICCODEX.
          </div>
          <div className='d-flex gap-5 justify-content-center w-100 fs-5'>
            <a
              href={`${baseUrl}terminos-y-condiciones`}
              target='_blank'
              type='button'
              className='text-black fw-bold text-nowrap'
              rel='noreferrer'
            >
              Términos de servicio
            </a>
            <a
              href={`${baseUrl}politica-de-privacidad`}
              target='_blank'
              type='button'
              className='text-black fw-bold text-nowrap'
              rel='noreferrer'
            >
              Política de privacidad
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
