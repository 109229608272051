import {Button, Modal} from 'react-bootstrap'

interface IModalALert {
  onHide: () => void
  show: boolean
}

const ModalALert = ({onHide, show}: IModalALert) => {
  return (
    <Modal show={show} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Body className='text-justify'>
        <span>
          Para continuar con la operación lo invitamos a adquirir su licencia ya que actualmente no
          tiene ninguna activa.
        </span>
      </Modal.Body>
      <Modal.Footer className='p-2'>
        <Button onClick={() => onHide()}>Actualizar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export {ModalALert}
