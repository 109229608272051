import {FC, useCallback, useEffect, useState} from 'react'
import {KTSVG} from 'src/_metronic//helpers'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'

//Modules
import {UserModel} from 'src/app/modules/auth/models/UserModel'
import TransferSend from './components/TransferSend'
import TransferToogle from './components/TransferToogle'
//Services
import {transferPendiente} from 'src/services/api/Transfer'

const Transfer: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [pendiente, setPendiente] = useState<any>('')

  const getTransferPendiente = useCallback(() => {
    transferPendiente(user.id)
      .then((res) => {
        setPendiente(res)
      })
      .catch(() => {
        setPendiente('')
      })
  }, [user.id])

  useEffect(() => {
    getTransferPendiente()
  }, [getTransferPendiente])

  return (
    <div className='modal fade' id='kt_modal_transfer' aria-hidden='true'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          {pendiente ? (
            <TransferToogle getTransferPendiente={getTransferPendiente} />
          ) : (
            <TransferSend getTransferPendiente={getTransferPendiente} />
          )}
        </div>
      </div>
    </div>
  )
}

export {Transfer}
