import {toAbsoluteUrl} from 'src/_metronic/helpers'

interface StepsProps {
  index: number
  img: string
  title: string
  text: string
}

export const Steps = ({img, index, text, title}: StepsProps) => {
  return (
    <div className='col'>
      <div className='card gap-4 p-5 h-100 d-flex flex-column' style={{maxHeight: '380px'}}>
        <div>
          <div className='circle-primary'>{index}</div>
          <div className='d-flex justify-content-center'>
            <img
              alt='Logo'
              src={toAbsoluteUrl(img)}
              className='d-block w-150px h-150px bg-round-img'
            />
          </div>
        </div>
        <h3 className='text-secondary fw-semibold text-center text-nowrap fs-5'>{title}</h3>
        <p className='text-gray text-center fs-7'>{text}</p>
      </div>
    </div>
  )
}
