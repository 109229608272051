const PoliticaPrivacidadPage = () => {
  return (
    <iframe
      style={{height: '100%', width: '100%'}}
      title='politica-de-privacidad'
      src='https://drive.google.com/file/d/1LCyVmA7ZxkXrt4hfcvaoU0KiabGvyr6J/preview'
      allow='autoplay'
    />
  )
}

export {PoliticaPrivacidadPage}
