import React, {useState} from 'react'
import {Alert, Form} from 'react-bootstrap'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'

import {UserModel} from 'src/app/modules/auth/models/UserModel'

type Props = {
  className: string
}

const TablesWidget5: React.FC<Props> = ({className}) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [copy, setCopy] = useState<boolean>(false)

  const baseUrl = process.env.REACT_APP_URL_ENVIRONMENTS || 'https://bluetradingcorp.com/'

  const referralUrl = `${baseUrl}auth/registration?ref=${user.referral_code}`

  const onCopyHandler = async () => {
    await navigator.clipboard.writeText(referralUrl)
    if (!copy) {
      setTimeout(() => setCopy(false), 2000)
    }
    setCopy(true)
  }

  return (
    <div className={`dashboard ${className}`}>
      <span className='card-title align-items-start flex-column'>
        <h3 className='dashboard-title'>Tu link de referido</h3>
        <span className='dashboard-sub-title mb-5'>Compartelo a tus invitados</span>
      </span>
      <Form.Control size='lg' value={referralUrl} disabled />
      {copy && (
        <Alert id='alert' key='success' variant='success'>
          Link de referido copiado
        </Alert>
      )}
      <button type='button' className='btn btn-primary btn-sm' onClick={onCopyHandler}>
        Copiar
      </button>
    </div>
  )
}

export {TablesWidget5}
