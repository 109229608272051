import axios, { AxiosResponse } from 'axios';
import { IPendientes } from 'src/models/Pendientes';
import { ISumSaldo } from 'src/models/SumSaldo';
import { API_ENDPOINTS, getEndPoint } from './utils/endpoints';

export const userDepositsSumVerificado = async (id: number): Promise<ISumSaldo> => {
    const url: string = getEndPoint(API_ENDPOINTS.USER_DEPOSITS.SUM_VERIFICADO, { id });

    try {
        const api_reponse: AxiosResponse = await axios.get(url);
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};

export const userDepositsPendiente = async (id: number): Promise<IPendientes> => {
    const url: string = getEndPoint(API_ENDPOINTS.USER_DEPOSITS.PENDIENTE, { id });

    try {
        const api_reponse: AxiosResponse = await axios.get(url);
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};

export const userDepositsPendienteDeposito = async (id: number): Promise<IPendientes> => {
    const url: string = getEndPoint(API_ENDPOINTS.USER_DEPOSITS.PENDIENTE_DEPOSITO, { id });

    try {
        const api_reponse: AxiosResponse = await axios.get(url);
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};

export const userBalance = async (id: number): Promise<any> => {
    const url: string = getEndPoint(API_ENDPOINTS.USER_DEPOSITS.BALANCE, { id });

    try {
        const api_reponse: AxiosResponse = await axios.get(url);
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};


export const userChart = async (id: number, start: string, end: string): Promise<any> => {
    const url: string = getEndPoint(API_ENDPOINTS.USER_DEPOSITS.CHART, { id, start, end });

    try {
        const api_reponse: AxiosResponse = await axios.get(url);
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};