import {KTSVG} from 'src/_metronic/helpers'
import {UserModel} from 'src/app/modules/auth/models/UserModel'
import {RootState} from 'src/setup'
import {shallowEqual, useSelector} from 'react-redux'

const HelpDrawer = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <div
      id='kt_help'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_help_toggle'
      data-kt-drawer-close='#kt_help_close'
    >
      <div className='card shadow-none rounded-0 w-100'>
        <div className='card-header' id='kt_help_header'>
          <h5 className='card-title fw-bold text-gray-600'>Soporte</h5>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_help_close'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </button>
          </div>
        </div>

        <div className='card-body' id='kt_help_body'>
          <div
            id='kt_help_scroll'
            className='hover-scroll-overlay-y'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_help_body'
            data-kt-scroll-dependencies='#kt_help_header'
            data-kt-scroll-offset='5px'
          >
            <div className='rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-10'>
              <h2 className='fw-bolder mb-5'>¿Necesitas soporte?</h2>
              <div className='fs-5 fw-bold mb-5'>
                <span className='text-gray-500'>
                  Puedes recibir atención de nuestro equipo de soporte dando click en el siguiente
                  link.
                </span>
              </div>
              <div className='d-flex flex-column align-items-center'>
                <div className='d-flex mb-5'>
                  <link
                    rel='stylesheet'
                    href='https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css'
                  />
                  <a
                    href={`https://api.whatsapp.com/send?phone=16162741115&text=Hola%20equipo%20Blue%20Requiero%20soporte%20con%20mi%20Usuario%20ID:${user.id}%20Correo:${user.email}`}
                    target='_blank'
                    rel='noreferrer'
                    style={{
                      width: '80px',
                      height: '80px',
                      backgroundColor: '#25d366',
                      color: '#FFF',
                      borderRadius: '15px',
                      textAlign: 'center',
                      fontSize: '56px',
                      boxShadow: '2px 2px 3px #999',
                    }}
                  >
                    <i className='fa fa-whatsapp' style={{marginTop: '11px', color: '#FFF'}}></i>
                  </a>
                </div>
                <a
                  href={`https://api.whatsapp.com/send?phone=16162741115&text=Hola%20equipo%20Blue%20Requiero%20soporte%20con%20mi%20Usuario%20ID:${user.id}%20Correo:${user.email}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <button
                    type='button'
                    className='btn btn-success rounded-pill text-white fw-bold fs-2'
                    style={{backgroundColor: '#04A89E'}}
                  >
                    Continuar al chat
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {HelpDrawer}
