import getEnvironment from 'src/env'
import { ENVIRONMENTS } from 'src/env/enviroments'

const ApiEndpointCache = new Map()

export const BASE_URI = {
    LOCAL: 'http://127.0.0.1:8000/api',
    DEV: 'https://bluetradingcorp.com/appdev/api',
    PRODUCTION: 'https://bluetradingcorp.com/app/api',
}

export const API_ENDPOINTS = {
    USER: {
        MEMBERSHIP_RENEW: '/users/membership/renew',
    },
    USER_TRADING: {
        ALL: '/user-trading/{id}',
        CREATE: '/user-trading',
    },
    USER_INFO: {
        ONE: '/users/info/{id}',
        CREATE: '/users/info',
        UPDATE: '/users/info',
        REFERRALS: '/users/query/{id}',
        REFERRALS_BALANCE: '/users/referals/{id}',
        REFERRALS_EXPIRATION: '/users/referals/expiration/{id}',
        SEND_CODE_WALLET: '/users/wallet/mail',
        VALID_CODE_WALLET: '/users/wallet/valid',
        REFERRALS_RANGES: '/users/referals/range/{id}'
    },
    USER_DEPOSITS: {
        SUM_VERIFICADO: '/user_deposits/{id}',
        PENDIENTE: '/user_deposits/pendiente/{id}',
        PENDIENTE_DEPOSITO: '/user_deposits/pendiente_deposito/{id}',
        BALANCE: '/user_deposits/month/{id}',
        CHART: '/user_deposits/chart/{start}/{end}/{id}'
    },
    PROFIT: {
        CREATE: '/profit'
    },
    LICENSE: {
        ONE: '/license/{id}',
        PENDING: '/license/pending/{id}',
        UPDATE: '/license/update',
        SEND: '/license',
        REPORT: '/deposit/report/{id}',
        REPORT_FILTER: '/deposit/report/{id}/{start}/{end}/{type}',
        G_LICENSE: '/license/glicense/{id}/{month}'
    },
    INVESTMENT_RETURNS: {
        ALL: '/investment_returns/{month}',
        COMPOUND: '/investment_returns/compound/{id}/{month}',
        COMPOUND_GLOBAL: '/investment_returns/compound/global/{month}',
        PROFIT_CHAIN: '/investment_returns/profit_per_chain/{id}/{month}',
        PERSONAL: '/investment_returns/personal/{id}/{month}',
    },
    PERFORMANCE_RETURNS: {
        ALL: '/performance_returns',
    },
    NEW_PASSWORD: {
        RESET: '/new-password',
    },
    FORGOT_PASSWORD: {
        RESET: '/forgot-password',
    },
    WITHDRAWAL: {
        SEND: '/withdrawal',
        REPORT: '/withdrawal/report/{id}',
        REPORT_FILTER: '/withdrawal/report/{id}/{start}/{end}/{type}',
        REPORT_FILTER_TRANSFER: '/withdrawal/report/transfer/{id}/{start}/{end}/{type}',
    },
    DEPOSIT: {
        SEND: '/deposit',
        REPORT: '/deposit/report/{id}',
        REPORT_FILTER: '/deposit/report/{id}/{start}/{end}/{type}',
    },
    TRANSFER: {
        SEND: '/transfer',
        APROVE: '/transfer/aprove',
        REJECT: '/transfer/reject',
        PENDIENTE: '/transfer/{id}',
    },
    TOOLS: {
        SEND: '/management',
        SEND_LICENSE: '/management/license',
        REPORT: '/management/report',
        REPORT_FILTER: '/management/report/{start}/{end}/{type}',
        EDIT_USER: '/user_management',
        REPORT_USER: '/user_management/report',
    },
    SUPPORT: {
        SEND_SUPPORT: '/support/send',
        EMAIL: '/support/mail'
    },
    RESET_PASSWORD: {
        CREATE: '/reset-password',
    },
}

export const getBaseUrl = (): string => {
    return getEnvironment() === ENVIRONMENTS.LOCAL
        ? BASE_URI.LOCAL
        : getEnvironment() === ENVIRONMENTS.DEVELOPMENT
            ? BASE_URI.DEV
            : BASE_URI.PRODUCTION
}

const createApiEndpoint = (url: string, params: any) => {
    const pattern = /\{([^\\{\\}]*)\}/g
    return url.replace(pattern, (a, b) => {
        const p = b.split('.')
        let r = params

        try {
            for (let s = 0; s < p.length; s++) {
                r = String(r[p[s]])
            }
        } catch (e) {
            r = a
        }
        if (typeof r === 'undefined') {
            r = 'undefined'
        } else if (r === null) {
            r = 'null'
        }

        return String(typeof r === 'string' || typeof r === 'number' ? r : typeof r)
    })
}

export const getEndPoint = (endpoint: string, params?: Record<string, unknown>): string => {
    const key = `${endpoint}-${JSON.stringify(params)}`
    const parameters = params ?? {}
    const cachedEndpoint = ApiEndpointCache.get(key)

    const checkCache = !!cachedEndpoint ?? false

    if (checkCache) {
        return cachedEndpoint
    }
    const result = getBaseUrl() + createApiEndpoint(endpoint, parameters)
    ApiEndpointCache.set(key, result)

    return result
}
