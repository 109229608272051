import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from 'src/services/api/ResetPassword'
import {Alert} from 'react-bootstrap'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'

const initialValues = {
  email: '',
  password: '',
  password_confirmation: '',
}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  let {token, email} = useParams()

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      const params = {
        token: token,
        email: email,
        password: values.password,
        password_confirmation: values.password,
      }
      setLoading(true)
      resetPassword(params)
        .then(() => {
          toogleSuccess()
        })
        .catch(() => {
          toogleError()
        })
    },
  })
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const toogleSuccess = () => {
    setSuccess(true)
    setTimeout(function () {
      setSuccess(false)
    }, 2000)
    setLoading(false)
  }

  const toogleError = () => {
    setError(true)
    setTimeout(function () {
      setError(false)
    }, 2000)
    setLoading(false)
  }

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Actualiza tu contraseña</h1>
          <div className='text-gray-400 fw-bold fs-4'>
            Esta es un área segura de la aplicación. Por favor, confirme su contraseña antes de
            continuar.
          </div>
        </div>
        <div className='mb-10 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Contraseña</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder=''
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
          </div>
          <div className='text-muted'>
            Use 8 o más caracteres con una combinación de letras, números y símbolos.
          </div>
        </div>
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6'>Confirmar Contraseña</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('password_confirmation')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid':
                  formik.touched.password_confirmation && formik.errors.password_confirmation,
              },
              {
                'is-valid':
                  formik.touched.password_confirmation && !formik.errors.password_confirmation,
              }
            )}
          />
          {formik.touched.password_confirmation && formik.errors.password_confirmation && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password_confirmation}</span>
              </div>
            </div>
          )}
        </div>
        {success && <Alert variant='success'>Contraseña restablecida</Alert>}
        {error && (
          <Alert variant='danger'>
            Lo sentimos, parece que se han detectado algunos errores, inténtalo de nuevo.
          </Alert>
        )}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_new_password_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            {!loading && 'Enviar'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Cargando...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancelar
            </button>
          </Link>
        </div>
      </form>
    </>
  )
}
