import {FC, useState} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useLayout} from '../core'
import {AboutUsPage} from 'src/app/modules/aboutus/AboutUsPage'

const Footer: FC = () => {
  const {classes} = useLayout()
  const [showModal, setShowModal] = useState(false)

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='#' className='text-gray-800 text-hover-primary' translate='no'>
            Blue Trading
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item'>
            <button type='button' className='btn menu-link' onClick={handleOpenModal}>
              Sobre nosotros
            </button>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}

      {/* Begin::Modal */}
      <Modal show={showModal} onHide={handleCloseModal} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Sobre nosotros</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AboutUsPage></AboutUsPage>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End::Modal */}
    </div>
  )
}

export {Footer}
