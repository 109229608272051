/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from 'src/_metronic/helpers'
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {SendMailSupport} from 'src/services/api/Support'

const registrationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato del Email es incorrecto')
    .min(3, 'Email no valido')
    .max(50, 'Limite de Email')
    .required('El correo es requerido'),
  text: Yup.string().min(3, 'El Mensaje es requerido').required('El Mensaje es requerido'),
})

const SupportNotificationsMenu = () => {
  const initialValues = {
    email: '',
    text: '',
  }
  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values) => {
      setLoading(true)
      SendMailSupport(values)
        .then(() => {
          setLoading(false)
          formik.setFieldValue('email', '')
          formik.setFieldValue('text', '')
        })
        .finally(() => {
          setLoading(false)
        })
    },
  })

  return (
    <div className='menu menu-sub menu-sub-dropdown' data-kt-menu='true'>
      <div style={{width: '390px'}}>
        <div
          className='d-flex flex-column bgi-no-repeat rounded-top'
          style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
        ></div>

        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
            <div className='d-flex flex-column px-9'>
              <div className='pt-10 pb-0'>
                <h3 className='text-dark text-center fw-bolder mb-10'>¿Necesitas soporte?</h3>

                <div className='text-center text-gray-600 fw-bold mb-10'>
                  Puedes recibir atención de nuestros equipo de soporte dando click en el siguiente
                  formulario.
                </div>
                <form
                  className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                  noValidate
                  id='kt_login_signup_form'
                  onSubmit={formik.handleSubmit}
                >
                  <div className='fv-row mb-8'>
                    <label className='form-label fw-bolder text-dark fs-6'>Correo</label>
                    <input
                      placeholder='Correo'
                      type='text'
                      autoComplete='off'
                      {...formik.getFieldProps('email')}
                      className={clsx('form-control form-control-lg form-control-solid', {
                        'is-invalid': formik.touched.email && formik.errors.email,
                      })}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.email}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='fv-row mb-8'>
                    <label className='form-label fw-bolder text-dark fs-6'>Mensaje</label>
                    <textarea
                      placeholder='Escribe por favor en qué podemos
                      ayudarte.'
                      rows={6}
                      maxLength={100}
                      {...formik.getFieldProps('text')}
                      className={clsx(
                        'form-control form-control-lg form-control-solid',
                        {
                          'is-invalid': formik.touched.text && formik.errors.text,
                        },
                        {
                          'is-valid': formik.touched.text && !formik.errors.text,
                        }
                      )}
                    />
                    {formik.touched.text && formik.errors.text && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.text}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      color: '#010D26',
                      fontWeight: '600',
                      fontSize: '16px',
                    }}
                  >
                    <button
                      style={{
                        borderRadius: '11px',
                      }}
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-lg btn-primary w-60 mb-5'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && <span className='indicator-label'>Enviar formulario</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Espere un momento...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {SupportNotificationsMenu}
