import {toAbsoluteUrl} from 'src/_metronic/helpers'

const Navbar = () => {
  const baseUrl = process.env.REACT_APP_URL_ENVIRONMENTS || 'https://bluetradingcorp.com/'
  const toogleNav = () => {
    const nav = document.getElementById('myNav')
    if (nav) {
      nav.style.display = 'block'
      if (nav.style.height === '100%') {
        nav.style.height = '0%'
      } else {
        nav.style.height = '100%'
      }
    }
  }

  const closeNav = () => {
    const nav = document.getElementById('myNav')
    if (nav && nav.style.height === '100%') {
      nav.style.display = 'none'
      nav.style.height = '0%'
    }
  }

  return (
    <header className='navbar navbar-expand-md bd-navbar landing-index'>
      <nav className='container-xxl flex-wrap flex-md-nowrap'>
        <div className='navbar-brand p-0 me-2'>
          <img alt='Logo' src={toAbsoluteUrl('/media/landing/logo.svg')} className='h-45px' />
        </div>
        <div className='d-block d-sm-none'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/landing/navbar.svg')}
            className='h-28px'
            onClick={() => toogleNav()}
          />
        </div>
        <div className='navbar-collapse d-none d-sm-block'>
          <ul className='navbar-nav flex-row flex-wrap ms-md-auto align-items-center'>
            <li className='nav-item col-auto' role='button'>
              <div className='nav-link p-5 fw-bolder text-primary'>INICIO</div>
            </li>
            <li className='nav-item col-auto' role='button'>
              <a href='#section1' className='nav-link p-5 text-primary'>
                ¿CÓMO EMPEZAR?
              </a>
            </li>
            <li className='nav-item col-auto' role='button'>
              <a href='#section2' className='nav-link p-5 text-primary'>
                NUESTROS SERVICIOS
              </a>
            </li>
            <li className='nav-item col-auto' role='button'>
              <a href='#section3' className='nav-link p-5 text-primary'>
                ¡CONOCE MÁS!
              </a>
            </li>
          </ul>
        </div>
        <div
          id='myNav'
          className='overlay overflow-hidden'
          style={{
            backgroundColor: 'rgba(0, 1, 48, 0.5)',
            zIndex: 10,
          }}
        >
          <video
            autoPlay
            loop
            muted
            style={{
              position: 'absolute',
              width: '100%',
              left: '50%',
              top: '50%',
              height: '100%',
              objectFit: 'cover',
              transform: 'translate(-50%, -50%)',
              zIndex: '-1',
            }}
            src={toAbsoluteUrl('/media/landing/banner-vertical.mp4')}
            className='d-block d-sm-none'
          />
          <div className='overlay-content'>
            <li className='nav-item col-auto'>
              <div className='nav-link py-5 fw-bolder text-primary' onClick={() => closeNav()}>
                INICIO
              </div>
            </li>
            <li className='nav-item col-auto'>
              <a href='#section1' className='nav-link py-5 text-primary' onClick={() => closeNav()}>
                ¿CÓMO EMPEZAR?
              </a>
            </li>
            <li className='nav-item col-auto'>
              <a href='#section2' className='nav-link py-5 text-primary' onClick={() => closeNav()}>
                NUESTROS SERVICIOS
              </a>
            </li>
            <li className='nav-item col-auto'>
              <a href='#section3' className='nav-link py-5 text-primary' onClick={() => closeNav()}>
                ¡CONOCE MÁS!
              </a>
            </li>
          </div>
        </div>
        <a
          href={`${baseUrl}auth/login`}
          target='_blank'
          type='button'
          className='nav-link p-5 btn btn-primary-l'
          rel='noreferrer'
        >
          INGRESAR
        </a>
      </nav>
    </header>
  )
}

export default Navbar
