import Navbar from './components/Navbar'
import Main from './components/Main'
import Section1 from './components/Section1'
import Section2 from './components/Section2'
import Section3 from './components/Section3'
import Footer from './components/Footer'
import {toAbsoluteUrl} from 'src/_metronic/helpers'

export function LandingPage() {
  return (
    <div className='position-relative'>
      <div className='symbol animate__animated animate__headShake position-fixed bottom-0 end-0 me-5 mb-5 z-index-2'>
        <a
          href={`https://api.whatsapp.com/send?phone=16162741115`}
          target='_blank'
          rel='noreferrer'
        >
          <img
            className='whatsapp-icon'
            alt='Logo'
            src={toAbsoluteUrl('/media/icons/duotune/communication/whatsapp.png')}
          />
        </a>
      </div>
      <Navbar />
      <Main />
      <Section1 />
      <Section2 />
      <Section3 />
      <Footer />
    </div>
  )
}
