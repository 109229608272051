import axios, { AxiosResponse } from 'axios';
import { API_ENDPOINTS, getEndPoint } from './utils/endpoints';

export const sendTransfer = async (params: any): Promise<any> => {
    const url: string = getEndPoint(API_ENDPOINTS.TRANSFER.SEND);
    try {
        const api_reponse: AxiosResponse = await axios.post(url, { ...params });
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};

export const aproveTransfer = async (params: any): Promise<any> => {
    const url: string = getEndPoint(API_ENDPOINTS.TRANSFER.APROVE);
    try {
        const api_reponse: AxiosResponse = await axios.post(url, { ...params });
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};

export const rejectTransfer = async (params: any): Promise<any> => {
    const url: string = getEndPoint(API_ENDPOINTS.TRANSFER.REJECT);
    try {
        const api_reponse: AxiosResponse = await axios.post(url, { ...params });
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};

export const transferPendiente = async (id: number):
    Promise<any> => {
    let url: string = getEndPoint(API_ENDPOINTS.TRANSFER.PENDIENTE, { id });
    try {
        const api_reponse: AxiosResponse = await axios.get(url);
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};