import {toAbsoluteUrl} from 'src/_metronic/helpers'

interface ServicesProps {
  clasImg?: string
  img: string
  text: any
  size: string[]
}

export const Services = ({img, text, size, clasImg}: ServicesProps) => {
  return (
    <div className='col'>
      <div
        className='gap-4 p-5 h-100 d-flex flex-column justify-content-center'
        style={{maxHeight: '360px'}}
      >
        <div>
          <div className='d-flex justify-content-center '>
            <img
              alt='Logo'
              src={toAbsoluteUrl(img)}
              className={`${clasImg ?? ''} d-block px-2`}
              style={{height: size[0], width: size[1]}}
            />
          </div>
        </div>
        <p className='text-center fs-7 text-nowrap'>
          {text.map((part: string, index: number) => (
            <span key={index}>
              {part}
              {index !== text.length - 1 && <br />}
            </span>
          ))}
        </p>
      </div>
    </div>
  )
}
