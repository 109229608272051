/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from 'src/_metronic//helpers'
import {useFormik} from 'formik'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import PioneerSub from './model/Subscription'
import NormalSub from './model/Subscription2'
import Wallets from './model/Wallets'
import * as auth from 'src/app/modules/auth/redux/AuthRedux'

//Modules
import {UserModel} from 'src/app/modules/auth/models/UserModel'
//Services
import {getPendingLicense, sendLicense, updateLicense} from 'src/services/api/License'
import {Alert} from 'react-bootstrap'
import {userDepositsSumVerificado} from 'src/services/api/UserDeposits'

const UpgradePlan = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch()
  const [currentState, setCurrentState] = useState<'' | 'month' | 'annual'>('')
  const [selected, setSelected] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [copy, setCopy] = useState<boolean>(false)
  const [wallet, setWallet] = useState<string>('SALDO')
  const [messageSuccess, setMessageSuccess] = useState<string>('')
  const [messageError, setMessageError] = useState<string>('')
  const [pendingLicense, setPendingLicense] = useState<boolean | undefined>()

  let Subscription = NormalSub
  if (user.pioneer) {
    Subscription = PioneerSub
  }

  const onCopyHandler = async () => {
    if (wallet) {
      await navigator.clipboard.writeText(wallet)
      if (!copy) {
        setTimeout(function () {
          setCopy(false)
        }, 2000)
      }
      setCopy(true)
    }
  }

  const initialValues = {
    file: [],
  }
  const formik = useFormik({
    initialValues,
    onSubmit: async (values: any) => {
      for (let i = 0; i < Subscription.length; i++) {
        if (Subscription[i].title === selected) {
          if (user.membership && Number(validDiscount(Subscription[i])) > 0) {
            if (currentState === 'month') {
              values.deposit = validDiscount(Subscription[i])
              values.package = Subscription[i].package
            } else {
              values.deposit = validDiscount(Subscription[i])
              values.package = Subscription[i].packageAnnual
            }
          } else {
            if (currentState === 'month') {
              values.deposit = Subscription[i].priceMonth
              values.package = Subscription[i].package
            } else {
              values.deposit = Subscription[i].priceAnnual
              values.package = Subscription[i].packageAnnual
            }
          }
        }
      }

      if (wallet === 'SALDO') {
        setLoading(true)
        let saldo = 0
        await userDepositsSumVerificado(user.id)
          .then((res) => {
            saldo = res.saldo
          })
          .catch(() => {})
        if (saldo >= values.deposit) {
          values.user_id = user.id
          values.subscription = currentState
          values.wallet = wallet
          updateLicense(values)
            .then((res) => {
              toogleSuccess('Paquete actualizado correctamente')
              user.membership = res.paquete
              user.membershipdate = new Date(res.fecha)
              user.membershipVencDate = new Date(res.vencimiento)
              user.periodo = res.periodo
              dispatch(auth.actions.setUser(user))
              getPending()
            })
            .catch(() => {
              toogleError('Error al actualizar el paquete')
            })
        } else {
          toogleError('No hay saldo suficiente saldo para su nueva licencia"')
        }
      }
      if (selectedFile) {
        setLoading(true)

        values.file = []
        values.user_id = user.id
        values.email = user.email
        values.file.push(selectedFile)
        values.subscription = currentState
        values.wallet = wallet
        try {
          await sendLicense(values)
          toogleSuccess('Comprobante enviado correctamente')
          getPending()
        } catch (ex) {
          toogleError('Error al enviar el comprobante')
        }
      }
    },
  })

  useEffect(() => {
    if (user && user.id && pendingLicense === undefined) {
      getPendingLicense(user.id)
        .then(() => {
          setPendingLicense(true)
        })
        .catch(() => {
          setPendingLicense(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const getPending = () => {
    getPendingLicense(user.id)
      .then(() => {
        setPendingLicense(true)
      })
      .catch(() => {
        setPendingLicense(false)
      })
  }

  const toogleSuccess = (message: string) => {
    setMessageSuccess(message)
    setSuccess(true)
    setTimeout(function () {
      setSuccess(false)
    }, 2000)
    setLoading(false)
  }

  const toogleError = (message: string) => {
    setMessageError(message)
    setError(true)
    setTimeout(function () {
      setError(false)
    }, 2000)
    setLoading(false)
  }

  const validDiscount = (plan: any) => {
    let isGreater = false
    let difference = 0
    let paquete = ''
    if (currentState !== user.periodo) {
      return 0
    }

    const venc = new Date(user.membershipVencDate).getTime()
    const date = new Date().toISOString().slice(0, 10)
    const dateNow = new Date(date).getTime()
    const daysVenc = Math.floor((venc - dateNow) / (1000 * 60 * 60 * 24)) + 1
    if (daysVenc <= 30) {
      return 0
    }

    for (let i = 0; i < Subscription.length; i++) {
      if (isGreater && Subscription[i].package === plan.package) {
        const ini = new Date(user.membershipdate).getTime()
        const now = new Date().toISOString().slice(0, 10)
        const today = new Date(now).getTime()
        difference = Math.floor((today - ini) / (1000 * 60 * 60 * 24))
        difference += 1

        if (currentState === 'month') {
          const calc = parseInt(paquete) / 2
          return (plan.priceMonth - calc).toFixed(2)
        } else {
          const monthsPassed = Math.floor(difference / 30)
          const monthsRemaining = 12 - monthsPassed
          const calc = (parseInt(paquete) / 12) * monthsRemaining
          return (plan.priceAnnual - calc).toFixed(2)
        }
      }
      if (currentState === 'month') {
        if (Subscription[i].package === user.membership) {
          isGreater = true
          paquete = Subscription[i].priceMonth
        }
      } else {
        if (Subscription[i].packageAnnual === user.membership) {
          isGreater = true
          paquete = Subscription[i].priceAnnual
        }
      }
    }
    return 0
  }

  return (
    <div className='modal fade' id='kt_modal_upgrade_plan' aria-hidden='true'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content rounded-6'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              onClick={() => setCurrentState('')}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body pt-0 pb-15 px-5 px-xl-8'>
            <div className='mb-13 text-center mx-md-15'>
              <h1 className='mb-3 text-primary' style={{fontWeight: '700', fontSize: '30px'}}>
                Activa tu Suscripción
              </h1>

              <div className='text-muted fw-bolder fs-5 px-5 d-flex flex-column gap-5 mx-md-15'>
                <div className='text-center mx-md-15'>
                  Explora nuestros planes de pago, diseñado para adaptarse a tus necesidades. Elige
                  la que se adapte a tus metas y estilo.
                </div>
                <div className='text-center mx-md-15'>
                  Recuerda, una vez que hayas seleccionado y pagado tu plan, para cambiar a otra
                  opción,{' '}
                  <span className='text-primary'>
                    se aplicara solo un descuento proporcional según el tiempo de vigencia de tu
                    licencia actual.
                  </span>
                </div>
              </div>
            </div>

            <div className='d-flex flex-column'>
              <div className='d-flex gap-5 mx-auto' data-kt-buttons='true'>
                <div
                  className={`btn fw-bolder fs-4 rounded-pill w-150px mx-3 ${
                    currentState === 'month' ? 'btn-primary' : 'btn-light'
                  }`}
                  style={
                    currentState === 'annual'
                      ? {border: '1px solid #04A89E'}
                      : {border: '1px solid transparent'}
                  }
                  onClick={() => {
                    setCurrentState('month')
                  }}
                >
                  BIMESTRAL
                </div>
                <div
                  className={`btn fw-bolder fs-4 rounded-pill w-150px mx-3 ${
                    currentState === 'annual' ? 'btn-primary' : 'btn-light'
                  }`}
                  style={
                    currentState === 'month'
                      ? {border: '1px solid #04A89E'}
                      : {border: '1px solid transparent'}
                  }
                  onClick={() => {
                    setCurrentState('annual')
                  }}
                >
                  ANUAL
                </div>
              </div>
              {currentState && (
                <form onSubmit={formik.handleSubmit} noValidate className='form mx-md-15'>
                  <div className='row mt-10'>
                    <div className='col-lg-6 mb-10'>
                      <div className='pb-5'>
                        <div className='form-label text-black fw-bolder fs-3'>
                          Seleccione el paquete a elegir
                        </div>
                        {currentState === 'annual' && (
                          <p className='fs-6 mb-0'>
                            Los usuarios que adquieran este plan no harán parte de ningún plan de
                            compensación.
                          </p>
                        )}
                      </div>
                      <div className='nav flex-column'>
                        {Subscription.map((plan: any, index: any) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                setSelected(plan.title)
                              }}
                              className={`card border border-secondary rounded-3 p-3 d-flex flex-row me-2 px-8 py-2 mb-5 ${
                                selected === plan.title ? 'bg-light' : ''
                              }`}
                              data-bs-toggle='tab'
                              data-bs-target={`#kt_upgrade_plan_${index}`}
                            >
                              <div className='col-auto d-flex align-items-center me-5 align-items-center'>
                                <div className='me-2'>
                                  <input
                                    className='radio-custom'
                                    type='radio'
                                    name='plan'
                                    value={plan.title}
                                    checked={selected === plan.title}
                                    onChange={(e) => setSelected(e.target.value)}
                                  />
                                </div>
                                <div>
                                  <img
                                    className='img-fluid'
                                    src={toAbsoluteUrl(
                                      `/media/plans/${
                                        currentState === 'month' ? plan.package : plan.packageAnnual
                                      }.svg`
                                    )}
                                    alt='plan'
                                  />
                                </div>
                              </div>
                              {user.membership && Number(validDiscount(plan)) > 0 ? (
                                <div className='col-auto ms-auto d-flex text-primary gap-4 align-items-center'>
                                  <span className='fs-2x fw-boldest'>$ {validDiscount(plan)}</span>
                                  <div>
                                    <span className='fs-2x fw-bolder text-decoration-line-through'>
                                      ${' '}
                                      {currentState === 'month'
                                        ? plan.priceMonth
                                        : plan.priceAnnual}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div className='col-auto ms-auto d-flex text-primary align-items-center'>
                                  <span className='fs-3x fw-bolder'>
                                    ${' '}
                                    {currentState === 'month' ? plan.priceMonth : plan.priceAnnual}
                                  </span>
                                </div>
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </div>

                    <div className='col-lg-6'>
                      <div className='tab-content rounded h-100 bg-light px-10 px-md-20 py-10 rounded-6'>
                        {Subscription.map((plan: any, index: any) => (
                          <React.Fragment key={index}>
                            {plan.title === selected && (
                              <div
                                className={
                                  `d-flex justify-content-between flex-column ali` +
                                  (plan.default && 'show active')
                                }
                                id={`kt_upgrade_plan_${index}`}
                                key={`up${index}`}
                              >
                                <div className='text-center'>
                                  <img
                                    className='img-fluid w-350px h-360px pb-5'
                                    src={toAbsoluteUrl(
                                      `/media/plans/details/${
                                        currentState === 'month' ? plan.package : plan.packageAnnual
                                      }.svg`
                                    )}
                                    alt='plan'
                                  />
                                </div>
                                <div className='d-flex flex-column gap-2'>
                                  {currentState === 'annual' ? (
                                    <div className='d-flex align-items-center gap-4'>
                                      <img
                                        className='h-15px'
                                        alt='checkbox'
                                        src={toAbsoluteUrl(`/media/svg/misc/check.svg`)}
                                      />
                                      <span className='text-gray fs-5 flex-grow-1'>
                                        {plan.features[0].title}
                                      </span>
                                    </div>
                                  ) : (
                                    plan.features.map((feature: any, i: any) => (
                                      <div
                                        className='d-flex align-items-center gap-4'
                                        key={`${i}-${feature.title}`}
                                      >
                                        <img
                                          className='h-15px'
                                          alt='checkbox'
                                          src={toAbsoluteUrl(`/media/svg/misc/check.svg`)}
                                        />
                                        <span className='text-gray fs-5 flex-grow-1'>
                                          {feature.title}
                                        </span>
                                      </div>
                                    ))
                                  )}
                                  {currentState === 'annual' && (
                                    <div className={`d-flex align-items-start gap-4`}>
                                      <img
                                        className='h-15px'
                                        alt='checkbox'
                                        src={toAbsoluteUrl(`/media/svg/misc/check-x.svg`)}
                                      />
                                      <span className='fs-5 flex-grow-1' style={{color: '#931527'}}>
                                        Los usuarios que adquieran este plan, no harán parte de
                                        ningún plan de compensación.
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className='pt-15'>
                                  <label className='form-label text-black fw-bolder fs-5'>
                                    Paga con las siguientes redes
                                  </label>
                                  <div className='row'>
                                    <select
                                      className='col form-select form-select-solid form-select-l border border-dark '
                                      onChange={(e) =>
                                        setWallet(
                                          e.target.options[e.target.options.selectedIndex].value
                                        )
                                      }
                                    >
                                      <option value='SALDO'>De tu saldo</option>
                                      {Wallets.map((e) => (
                                        <option key={e.value} value={e.value}>
                                          {e.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className='pt-10 text-center'>
                                    {Wallets.map(
                                      (e) =>
                                        e.value === wallet && (
                                          <img
                                            key={`img${e.value}`}
                                            className='img-fluid mw-200 mh-400px'
                                            alt='wallet'
                                            src={toAbsoluteUrl(e.image)}
                                          />
                                        )
                                    )}
                                    {wallet !== 'SALDO' && (
                                      <div className='d-flex align-items-end'>
                                        <input
                                          type='text'
                                          className='form-control text-primary mt-5 me-2'
                                          style={{height: '40px'}}
                                          value={wallet}
                                          disabled
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-sm'
                                          style={{height: '40px'}}
                                          onClick={onCopyHandler}
                                        >
                                          Copiar
                                        </button>
                                      </div>
                                    )}
                                    {copy && (
                                      <Alert id='alert' key='success' variant='success'>
                                        Link de billetera copiado
                                      </Alert>
                                    )}
                                  </div>
                                  {wallet !== 'SALDO' && (
                                    <>
                                      <hr style={{margin: '2rem 0'}} />
                                      <h2 className='fw-bolder'>Adjuntar comprobante</h2>
                                    </>
                                  )}
                                  <div style={{marginBottom: '1rem'}}>
                                    {wallet !== 'SALDO' && (
                                      <input
                                        type='file'
                                        className='form-control'
                                        onChange={(e) =>
                                          setSelectedFile(
                                            e?.target && e.target.files && e.target.files[0]
                                          )
                                        }
                                      />
                                    )}
                                    {success && (
                                      <Alert className='mt-5' variant='success'>
                                        {messageSuccess}
                                      </Alert>
                                    )}
                                    {error && (
                                      <Alert className='mt-5' variant='danger'>
                                        {messageError}
                                      </Alert>
                                    )}
                                  </div>
                                  {pendingLicense && (
                                    <Alert className='mt-5' variant='danger'>
                                      Tiene una licencia en verificación, si requiere modificarla
                                      puedes contactar a soporte Blue trading Corp
                                    </Alert>
                                  )}
                                  <div className='col-auto text-end'>
                                    <button
                                      type='submit'
                                      className='btn fw-bolder fs-4 rounded-pill btn-primary'
                                      disabled={
                                        pendingLicense ||
                                        loading ||
                                        user.membership === plan.package ||
                                        user.membership === plan.packageAnnual
                                      }
                                    >
                                      {!loading && 'Enviar'}
                                      {loading && (
                                        <span
                                          className='indicator-progress'
                                          style={{display: 'block'}}
                                        >
                                          Cargando...
                                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                  {(user.membership === plan.package ||
                                    user.membership === plan.packageAnnual) && (
                                    <div className='alert alert-danger' role='alert'>
                                      Actualmente tiene activa la misma licencia, elija una licencia
                                      que aplique
                                    </div>
                                  )}
                                  <div className='d-flex justify-content-end'></div>
                                  {Number(validDiscount(plan)) > 0 && (
                                    <div
                                      className='d-flex flex-center mt-5 p-5'
                                      style={{background: '#FFFFFF', borderRadius: '18px'}}
                                      role='alert'
                                    >
                                      <span className='svg-icon svg-icon-1 me-5'>
                                        <img
                                          alt='alerta'
                                          data-bs-toggle='tooltip'
                                          className='h-60px'
                                          src={toAbsoluteUrl(
                                            `/media/icons/duotune/alerts/alert-1.png`
                                          )}
                                        />
                                      </span>
                                      <span
                                        style={{
                                          color: '#010D26',
                                          fontWeight: '300',
                                          fontSize: '12px',
                                        }}
                                      >
                                        ¡Esta operación{' '}
                                        <strong>
                                          no modificará su fecha de vencimiento del plan
                                        </strong>{' '}
                                        por lo que una vez esté dentro de los 3 días a su
                                        vencimiento, se habilitará la compra de su nueva licencia!
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                        {currentState && !selected && (
                          <div className='text-center'>
                            <div className='text-center fs-3 lh-sm'>
                              <span className='text-primary fw-bolder fs-2'>Explora y escoge</span>{' '}
                              <br />
                              uno de nuestros planes.
                            </div>
                            <img
                              className='img-fluid py-4'
                              src={toAbsoluteUrl(
                                `/media/plans/details/${
                                  currentState === 'annual' ? 'a-base' : 'b-base'
                                }.svg`
                              )}
                              alt='detalle del plan'
                            />
                            <div className='text-center fs-3 lh-sm'>
                              <span className='text-primary fw-bolder fs-1'>Selecciona</span> el que
                              mejor encaje <br />
                              con tus objetivos y preferencias.
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>

            <div
              className={`d-flex flex-center flex-row-fluid  ${
                currentState !== '' ? 'pt-12' : 'pt-2'
              }`}
            >
              <button
                type='reset'
                className='mt-5 mx-auto btn text-white fw-bolder fs-4 btn-primary rounded-pill w-150px '
                data-bs-dismiss='modal'
                onClick={() => setCurrentState('')}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {UpgradePlan}
