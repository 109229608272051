import {FC, useCallback, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from 'src/_metronic//helpers'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {Alert} from 'react-bootstrap'

//Modules
import {UserModel} from 'src/app/modules/auth/models/UserModel'
import {IPendientes} from 'src/models/Pendientes'
import {ModalALert} from './ModalAlert'
import {ModalLimit} from './ModalLimit'
import PioneerSub from '../upgrade-plan/model/Subscription'
import NormalSub from '../upgrade-plan/model/Subscription2'

//Services
import {
  userDepositsPendienteDeposito,
  userDepositsSumVerificado,
} from 'src/services/api/UserDeposits'
import {sendDeposit} from 'src/services/api/Deposit'

const Deposits: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [pendientes, setPendientes] = useState<IPendientes>()
  const [saldoPendiente, setSaldoPendiente] = useState<number>(0)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [copy, setCopy] = useState<boolean>(false)
  const [modalAlert, setModalAlert] = useState(false)
  const [modalLimit, setModalLimit] = useState(false)
  const [saldo, setSaldo] = useState<any>()

  let Subscription = NormalSub
  if (user.pioneer) {
    Subscription = PioneerSub
  }

  const DepositsSchema = Yup.object().shape({
    deposito: Yup.number().min(1, 'Ingrese deposito valido').required('El deposito es requerido'),
  })

  const initialValues = {
    metodoPago: '0',
    deposito: '0',
    proof: null,
    file: [],
  }
  const formik = useFormik({
    initialValues,
    validationSchema: DepositsSchema,
    onSubmit: async (values: any, {resetForm}) => {
      if (!user.membership && !user.membershipValidation) {
        setModalAlert(true)
      } else if (limiteDeposito(values.deposito)) {
        setModalLimit(true)
      } else {
        if (selectedFile) {
          setLoading(true)
          values.file = []
          values.id = user.id
          values.email = user.email
          values.file.push(selectedFile)
          try {
            await sendDeposit(values).then((res) => {
              if (res.error) {
                toogleError(res.error)
              } else {
                toogleSuccess(res)
                resetForm()
              }
            })
          } catch (e) {
            toogleError('Deposito fallido')
            resetForm()
          }
        }
      }
    },
  })

  const getUserDepositPendiente = useCallback(() => {
    userDepositsPendienteDeposito(user.id).then((data) => {
      setPendientes(data)
      setSaldoPendiente(data.sum)
    })
    userDepositsSumVerificado(user.id)
      .then((res) => {
        setSaldo(res.saldo)
      })
      .catch(() => {})
  }, [user.id])

  useEffect(() => {
    getUserDepositPendiente()
  }, [getUserDepositPendiente])

  const toogleSuccess = (res: string) => {
    getUserDepositPendiente()
    setSuccess(res)
    setTimeout(function () {
      setSuccess('')
    }, 10000)
    setLoading(false)
  }

  const toogleError = (error: string) => {
    setError(error)
    setTimeout(function () {
      setError('')
    }, 4000)
    setLoading(false)
  }

  const onCopyHandler = async () => {
    await navigator.clipboard.writeText(`TDEVtYHMi4MV9E8uxUm9zDXeMiBMRkv29u`)
    if (!copy) {
      setTimeout(function () {
        setCopy(false)
      }, 2000)
    }
    setCopy(true)
  }

  const limiteDeposito = (deposit: string) => {
    if (user.membership === 'B150' || user.membership === 'A300') {
      return false
    }

    let amount = Subscription.find(
      (e) => e.package === user.membership || e.packageAnnual === user.membership
    )
    if (!amount || saldo + parseFloat(deposit) > amount.limitUpper) {
      return true
    }
    return false
  }

  return (
    <div className='modal fade' id='kt_modal_deposits' aria-hidden='true'>
      <div className='modal-dialog modal-lg'>
        <div className='modal-content rounded'>
          <div className='modal-header justify-content-end border-0 pb-0'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body pt-0 pb-15 px-5 px-md-15'>
            <div className='mb-13 text-center'>
              <h1 className='fs-2qx fw-boldest text-center'>Deposita a tu cuenta</h1>

              <div className='text-muted fw-bold fs-5 fs-md-4'>
                Si necesitas más información del proceso{' '}
                <a href='/#' className='link-primary fw-bolder'>
                  revisa esta guia.
                </a>
              </div>
            </div>
            <div className='container row m-0 p-0'>
              <div className='col rounded h-100 bg-light p-10 mx-5'>
                <div id='kt_account_profile_details' className='collapse show'>
                  <div className='mb-6 text-center'>
                    <h2 className='text-primary'>Seleccione su metodo de pago</h2>
                  </div>
                  <div className='mb-10 '>
                    <div className='col-lg-8 mx-auto'>
                      <select
                        className='form-select form-select-border text-center border-dark '
                        {...formik.getFieldProps('metodoPago')}
                      >
                        <option value=''>Red TRC20 (USDT)</option>
                      </select>
                    </div>
                  </div>
                  <div className='text-center'>
                    <img
                      className='mw-md-400px img-fluid img-md-thumbnail'
                      style={{width: 'inherit'}}
                      alt='Binance-20'
                      src={toAbsoluteUrl('/media/deposit/depositPayQr.svg')}
                    />
                    <div className='d-flex align-items-baseline'>
                      <input
                        type='text'
                        className='form-control text-primary mt-5 me-2'
                        style={{height: '40px'}}
                        value={`TDEVtYHMi4MV9E8uxUm9zDXeMiBMRkv29u`}
                        readOnly
                      />
                      <button
                        type='button'
                        className='btn btn-primary btn-sm'
                        style={{height: '40px'}}
                        onClick={onCopyHandler}
                      >
                        Copiar
                      </button>
                    </div>
                    {copy && (
                      <Alert id='alert' key='success' variant='success'>
                        Link de billetera copiado
                      </Alert>
                    )}
                  </div>

                  <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body'>
                      <div className='fw-bold my-6'>
                        <div className='row mb-6 d-flex justify-content-center'>
                          <div className='col-lg-5 col-form-label fw-bold fs-6'>
                            <label className='text-primary fs-2 fw-bolder' translate='no'>
                              Depositar:<sup>*</sup>
                            </label>
                          </div>
                          <div className='col-lg-6 fv-row ps-0'>
                            <div className='input-group'>
                              <input
                                type='number'
                                className='form-control rounded rounded-left'
                                placeholder=''
                                {...formik.getFieldProps('deposito')}
                                onChange={(e) => {
                                  formik.handleChange(e)
                                }}
                              />
                              <span className='input-group-text' id='basic-addon1' translate='no'>
                                USD
                              </span>
                            </div>
                            {formik.touched.deposito && formik.errors.deposito && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.deposito}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='row mb-6'>
                        <hr />
                        <div className='row mb-2 align-items-lg-center'>
                          <h2 className='fw-bolder'>Adjuntar comprobante</h2>
                          <div style={{marginBottom: '1rem'}}>
                            <input
                              type='file'
                              className='form-control'
                              onChange={(e) =>
                                setSelectedFile(e?.target && e.target.files && e.target.files[0])
                              }
                            />
                          </div>
                        </div>

                        {success && <Alert variant='success'>{success}</Alert>}
                        {error && <Alert variant='danger'>{error}</Alert>}
                        <div className=' d-flex flex-row-fluid'>
                          <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Procesar'}
                            {loading && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Cargando...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {saldoPendiente !== 0 && (
                <div className='col rounded h-100 py-10 mx-5'>
                  <div className='mb-10 text-center '>
                    <h2 className='text-primary'>Transacciones en Verificación</h2>
                  </div>
                  <div className='table-responsive pb-15'>
                    <table className='table text-center'>
                      <thead className='table-light'>
                        <tr>
                          <th className='fw-bold'>&nbsp;# Transacción</th>
                          <th className='fw-bold'>Fecha de transacción</th>
                          <th className='fw-bold'>Deposito&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody className='border'>
                        {pendientes?.data.map((value, i) => {
                          return (
                            <tr className='border' key={i}>
                              <th>{value.id}</th>
                              <td>{value.created_at.substring(0, 10)}</td>
                              <td>{value.cantidad_depositada}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <sub>
                      <strong>
                        "Tu deposito será Procesado el siguiente día hábil, desde este día estará
                        aprobado y generando profit"
                      </strong>
                    </sub>
                  </div>
                </div>
              )}
            </div>
            <div className='d-flex flex-center flex-row-fluid pt-12'>
              <button type='reset' className='btn btn-light me-3' data-bs-dismiss='modal'>
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalALert show={modalAlert} onHide={() => setModalAlert(false)} />
      <ModalLimit
        show={modalLimit}
        onHide={() => setModalLimit(false)}
        membershipVencDate={user.membershipVencDate}
      />
    </div>
  )
}

export {Deposits}
