/* eslint-disable react-hooks/exhaustive-deps */
import {useRef, useEffect} from 'react'
import {
  createChart,
  IChartApi,
  DeepPartial,
  ChartOptions,
  CrosshairMode,
  ISeriesApi,
  CandlestickSeriesPartialOptions,
} from 'lightweight-charts'

interface ChartLightWeightProps {
  candleData: {
    time: string
    open: number
    high: number
    low: number
    close: number
  }[]
  date: string[]
}

const ChartLightWeight = ({candleData, date}: ChartLightWeightProps) => {
  const chartContainerRef = useRef<HTMLDivElement>(null)
  let chart: IChartApi | null = null

  useEffect(() => {
    if (chartContainerRef.current) {
      const chartOptions: DeepPartial<ChartOptions> = {
        height: 250,
        layout: {
          background: {
            color: 'transparent',
          },
          textColor: 'rgba(255, 255, 255, 0.9)',
        },
        grid: {
          vertLines: {
            color: 'transparent',
          },
          horzLines: {
            color: 'transparent',
          },
        },
        crosshair: {
          mode: CrosshairMode.Hidden, // +
        },
        rightPriceScale: {
          borderColor: 'transparent',
          // visible: false,
        },
        timeScale: {
          borderColor: 'transparent',
        },
      }
      chart = createChart(chartContainerRef.current, chartOptions)

      const candleSeriesOptions: CandlestickSeriesPartialOptions = {
        upColor: '#12FFF3',
        borderUpColor: '#12FFF3',
        wickUpColor: '#12FFF3',
        downColor: '#0B1D33',
        borderDownColor: '#0B1D33',
        wickDownColor: '#0B1D33',
        lastValueVisible: false,
      }
      const candleSeries: ISeriesApi<'Candlestick'> =
        chart.addCandlestickSeries(candleSeriesOptions)
      candleSeries.setData(candleData)

      chart.timeScale().setVisibleRange({
        from: date[0],
        to: date[1],
      })
    }

    return () => {
      if (chart) {
        chart.remove()
        chart = null
      }
    }
  }, [date])

  return <div ref={chartContainerRef} />
}

export default ChartLightWeight
