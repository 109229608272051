import {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {Alert} from 'react-bootstrap'

//Modules
import {UserModel} from 'src/app/modules/auth/models/UserModel'
//Services
import {aproveTransfer, rejectTransfer} from 'src/services/api/Transfer'

interface propsData {
  getTransferPendiente: () => void
}

const TransferToogle = ({getTransferPendiente}: propsData) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<string>('')
  const [error, setError] = useState<string>('')

  const TransferSchema = Yup.object().shape({
    code: Yup.number().required('El Codigo es requerido'),
  })

  const initialValues = {
    code: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: TransferSchema,
    onSubmit: async (values: any, {resetForm}) => {
      setLoading(true)
      const params = {
        id: user.id,
        code: values.code,
      }
      aproveTransfer(params)
        .then((res) => {
          if (res.data) {
            toogleSuccess(res.data)
          } else {
            toogleError(res.error)
          }
        })
        .catch(() => {
          toogleError('Error')
        })
        .finally(() => {
          getTransferPendiente()
          resetForm()
        })
    },
  })

  const sendReject = () => {
    setLoading(true)
    const params = {
      id: user.id,
    }
    rejectTransfer(params)
      .then((res) => {
        if (res.data) {
          toogleSuccess(res.data)
        } else {
          toogleError(res.error)
        }
      })
      .catch(() => {
        toogleError('Error')
      })
      .finally(() => {
        getTransferPendiente()
      })
  }

  const toogleSuccess = (res: string) => {
    setSuccess(res)
    setTimeout(function () {
      setSuccess('')
    }, 10000)
    setLoading(false)
  }

  const toogleError = (error: string) => {
    setError(error)
    setTimeout(function () {
      setError('')
    }, 4000)
    setLoading(false)
  }

  return (
    <div className='modal-body pt-0 pb-15 px-5 px-md-15'>
      <h1 className='fs-2qx fw-boldest text-center pb-5'>Transferencia en proceso</h1>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top'>
            <div className='card fw-bold my-6'>
              <div className='row mb-6 d-flex justify-content-center'>
                <div className='col-lg-4 col-form-label fw-bold fs-6'>
                  <label className='required'>Código de verificación</label>
                </div>

                <div className='col-lg-6 fv-row'>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=''
                    {...formik.getFieldProps('code')}
                    onChange={(e) => {
                      formik.handleChange(e)
                    }}
                  />
                  {formik.touched.code && formik.errors.code && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.code}</div>
                    </div>
                  )}
                </div>
              </div>

              {success && <Alert variant='success'>{success}</Alert>}
              {error && <Alert variant='danger'>{error}</Alert>}

              <div className='card-footer d-flex flex-center flex-row-fluid pt-12'>
                <button className='btn btn-danger me-3' onClick={() => sendReject()}>
                  Rechazar
                </button>
                {!loading && (
                  <button type='submit' className='btn btn-primary'>
                    Transferir
                  </button>
                )}
                {loading && (
                  <button className='btn btn-primary'>
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Cargando...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default TransferToogle
