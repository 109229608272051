import {toAbsoluteUrl} from 'src/_metronic/helpers'

const Section3 = () => {
  return (
    <section
      id='section3'
      className='bd-masthead text-white landing-index position-relative'
      style={{
        background: 'linear-gradient(180deg, #FFFFFF -45.04%, #CED1D2 146.57%)',
      }}
    >
      <div className='bd-masthead'>
        <div
          className='container-xxl bd-gutter '
          style={{
            paddingTop: '3rem',
            paddingBottom: '3rem',
          }}
        >
          <div className='d-flex flex-column gap-5 my-auto align-items-center align-items-md-start'>
            <h2 className='text-primary fw-semibold text-center text-md-start'>
              Somos tu socio <br />
              <span className='text-secondary'>en el éxito financiero.</span>
            </h2>
            <a
              href='https://t.me/bluetradingcorp'
              target='_blank'
              type='button'
              className='col-auto me-5 btn btn-primary-l'
              rel='noreferrer'
            >
              DESCUBRE NUESTRO RENDIMIENTO
            </a>
          </div>
          <div className='h-150px h-md-120px h-lg-10px' />
        </div>
        <div
          className='bd-masthead text-white landing-index'
          style={{
            background: 'linear-gradient(180deg, #04A89E -45.04%, #04A89E 146.57%)',
            textAlign: 'justify',
            paddingTop: '3rem',
            paddingBottom: '3rem',
          }}
        >
          <div className='h-150px h-md-120px h-lg-10px' />
          <div className='container-xxl bd-gutter mt-5'>
            <p className='col-md-12 col-lg-7 me-auto fs-3 fs-md-1 text-response-imagen'>
              En BLUE TRADING CORP, nos dedicamos a EMPODERARTE en el mundo financiero. Destacamos
              al permitir a nuestros usuarios explorar intercambios financieros a través de Alianzas
              Corporativas exclusivas con socios estratégicos. Somos la guías ofreciendo
              conocimientos especializados y oportunidades únicas en inversiones.
            </p>
          </div>
        </div>
      </div>

      <div className='position-absolute top-50 end-0 translate-middle-y p-5 slider-absolute'>
        <div id='carouselExampleFade' className='carousel carousel-fade' data-bs-ride='carousel'>
          <div className='carousel-inner'>
            <div className='carousel-item active'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/landing/slider/slider1.webp')}
                className='d-block h-300px'
              />
            </div>
            <div className='carousel-item'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/landing/slider/slider2.webp')}
                className='d-block h-300px'
              />
            </div>
            <div className='carousel-item'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/landing/slider/slider3.webp')}
                className='d-block h-300px'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section3
