import { useState, useEffect } from "react";

const AboutUsPage = () => {
  const [height, setHeight] = useState(window.innerHeight * 0.7);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight * 0.7);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <iframe
      style={{ height: `${height}px`, width: "100%" }}
      title="sobre-nosotros"
      src="https://drive.google.com/file/d/1sgGnba98OtZMnDsnbYXvYyi-XzPGxPNK/preview"
      allow="autoplay"
    />
  );
};

export { AboutUsPage };