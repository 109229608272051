import axios, { AxiosResponse } from 'axios';
import { ILicense } from 'src/models/License';
import { API_ENDPOINTS, getEndPoint } from './utils/endpoints';

export const getLicense = async (id: number): Promise<ILicense> => {
    const url: string = getEndPoint(API_ENDPOINTS.LICENSE.ONE, { id });
    try {
        const api_reponse: AxiosResponse = await axios.get(url);
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};

export const getPendingLicense = async (id: number): Promise<ILicense> => {
    const url: string = getEndPoint(API_ENDPOINTS.LICENSE.PENDING, { id });
    try {
        const api_reponse: AxiosResponse = await axios.get(url);
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};

export const updateLicense = async (params: any): Promise<ILicense> => {
    const url: string = getEndPoint(API_ENDPOINTS.LICENSE.UPDATE);
    try {
        const api_reponse: AxiosResponse = await axios.post(url, { ...params });
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};

export const sendLicense = (values: any): Promise<any> => {
    const formData = new FormData();
    formData.append("user_id", values.user_id);
    formData.append("email", values.email);
    formData.append("file", values.file[0]);
    formData.append("package", values.package);
    formData.append("subscription", values.subscription);
    formData.append("deposit", values.deposit);
    formData.append("wallet", values.wallet);
    const url: string = getEndPoint(API_ENDPOINTS.LICENSE.SEND);

    return axios
        .post(url, formData)
        .then((d: AxiosResponse<any>) => d.data)
}

export const GLicense = async (id: number, month: string): Promise<any[]> => {
    const url: string = getEndPoint(API_ENDPOINTS.LICENSE.G_LICENSE, { id, month });

    try {
        const api_reponse: AxiosResponse = await axios.get(url);
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};