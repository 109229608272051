import axios, { AxiosResponse } from 'axios'
import { IDepositReportPersonal } from 'src/models/History'
import { API_ENDPOINTS, getEndPoint } from './utils/endpoints'

export const sendDeposit = (values: any): Promise<any> => {
    const formData = new FormData()
    formData.append('id', values.id)
    formData.append('email', values.email)
    formData.append('deposito', values.deposito)
    formData.append('file', values.file[0])
    const url: string = getEndPoint(API_ENDPOINTS.DEPOSIT.SEND)

    return axios.post(url, formData).then((d: AxiosResponse<any>) => d.data)
}

export const reportDepositPersonal = async (
    id: number,
    type: string,
    start?: string,
    end?: string,
): Promise<IDepositReportPersonal[]> => {
    let url: string = getEndPoint(API_ENDPOINTS.DEPOSIT.REPORT, { id })
    if ((start && end) || type !== 'none') {
        start = start || 'none'
        end = end || 'none'
        url = getEndPoint(API_ENDPOINTS.DEPOSIT.REPORT_FILTER, { id, start, end, type })
    }

    try {
        const api_reponse: AxiosResponse = await axios.get(url)
        if (api_reponse.data) {
            return api_reponse.data
        }
        throw Error(api_reponse.data.errorMessage)
    } catch (e) {
        const typedErr = e as Error
        throw typedErr
    }
}
