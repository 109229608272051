import {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'src/setup'
import {UserModel} from '../../auth/models/UserModel'

//Services
import {userChart, userDepositsSumVerificado} from 'src/services/api/UserDeposits'
//Components
import {toAbsoluteUrl} from 'src/_metronic/helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import ChartLightWeight from './ChartLightWeight'

const TuSaldoHorizontal = () => {
  const dateEnd = new Date().toISOString().slice(0, 10)
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [deposito, setDeposito] = useState<any>()
  const [chartData, setChartData] = useState<any>()
  const [date, setDate] = useState([
    new Date(Date.now()).toISOString().slice(0, 10),
    new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10),
  ])
  const [activeButton, setActiveButton] = useState('mensual')

  useEffect(() => {
    if (user.id) {
      userDepositsSumVerificado(user.id).then((res) => {
        setDeposito(res)
      })
    }
  }, [user.id, date])

  useEffect(() => {
    if (user.id && !chartData && user.created_at) {
      const today = new Date(Date.now()).toISOString().slice(0, 10)
      userChart(user.id, user.created_at.split('T')[0], today).then((res) => {
        setChartData(res)
      })
    }
  }, [user, chartData])

  const setMonthly = () => {
    const dateStart = new Date(new Date().setMonth(new Date().getMonth() - 1))
      .toISOString()
      .slice(0, 10)
    setDate([dateStart, dateEnd])
    setActiveButton('mensual')
  }

  const setAnnual = () => {
    const startOfSameMonthLastYear = new Date(
      new Date().getFullYear() - 1,
      new Date().getMonth(),
      1
    )
      .toISOString()
      .slice(0, 10)
    setDate([startOfSameMonthLastYear, dateEnd])
    setActiveButton('anual')
  }

  const setTotal = () => {
    if (user.created_at) {
      setDate([user.created_at.split('T')[0], dateEnd])
      setActiveButton('total')
    }
  }

  const value = [
    {
      text: 'Saldo Aprobado.',
      tooltip: `Depositos + rendimiento de meses anteriores + ganancias por referidos de meses anteriores.`,
      value: deposito?.saldo.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    },
    {
      text: 'Profit general.',
      tooltip: `Rendimiento sobre el saldo total`,
      value: deposito?.profit.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    },
    {
      text: 'Rendimiento del mes.',
      tooltip: `Porcentaje del profit general sobre el saldo aprobado.`,
      value: `${deposito?.rendimientoMes !== undefined ? deposito?.rendimientoMes + '%' : ''}`,
    },
    {
      text: 'Profit Personal.',
      tooltip: `Corresponde al 60% del profit general y se sumará a tu saldo aprobado al cierre del mes.`,
      value: deposito?.profitPersonal.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    },
    {
      text: 'Total.',
      tooltip: `Suma del saldo aprobado + el profit personal; este saldo será igual al saldo aprobado, el primer día de cada mes.`,
      value: deposito?.total.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    },
    {
      text: 'Ganancia por venta de Licencia.',
      tooltip: `Saldo por comisión del 30% por ventas de licencia de primer nivel + 15% en segundo nivel + 5% en tercer nivel.`,
      value: `${deposito?.licencia !== undefined ? '$' + deposito?.licencia : ''}`,
    },
  ]

  return (
    <div className='dashboard mb-10'>
      <h3 className='dashboard-title mb-5'>Tu Saldo</h3>
      <div className='btn-group w-25' role='group'>
        <button
          type='button'
          className={`btn btn-sm btn-primary fs-4 ${activeButton === 'mensual' ? 'active' : ''}`}
          aria-pressed={activeButton === 'mensual' ? 'true' : 'false'}
          onClick={setMonthly}
        >
          Mensual
        </button>
        <button
          type='button'
          className={`btn btn-sm btn-primary fs-4 ${activeButton === 'anual' ? 'active' : ''}`}
          aria-pressed={activeButton === 'anual' ? 'true' : 'false'}
          onClick={setAnnual}
        >
          Anual
        </button>
        <button
          type='button'
          className={`btn btn-sm btn-primary fs-4 ${activeButton === 'total' ? 'active' : ''}`}
          aria-pressed={activeButton === 'total' ? 'true' : 'false'}
          onClick={setTotal}
        >
          Total
        </button>
      </div>
      {chartData ? (
        <div
          className='card-xl-stretch w-100 rounded-3'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/misc/balance.svg')})`,
            backgroundSize: 'cover',
          }}
        >
          <ChartLightWeight candleData={chartData} date={date} />
        </div>
      ) : (
        // <ChartBalance
        //   className='card-xl-stretch w-100'
        //   chartColor='primary'
        //   chartHeight='200px'
        //   strokeColor='#0072C1'
        //   dataFull={data}
        // />
        <div className='dashboard-card dashboard-2fr'>
          <div className='spinner-border text-secondary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}
      <div className='container-fluid p-0'>
        {deposito ? (
          <div className='row row-cols-sm-2 row-cols-xxl-6 g-2 g-sm-4 g-md-5 justify-content-center'>
            {value.map((item, index) => (
              <div key={index} className='col' style={{minWidth: '140px', maxWidth: '180px'}}>
                <div
                  className={`card ${
                    index !== 3 && index !== 4 ? 'bg-info' : 'bg-info-white'
                  } text-center p-1 h-100px`}
                >
                  <OverlayTrigger overlay={<Tooltip id={`tooltip-top`}>{item.tooltip}</Tooltip>}>
                    <img
                      alt='alerta'
                      className='img-fluid h-20px ms-auto me-2 mt-1'
                      src={toAbsoluteUrl(`/media/icons/duotune/alerts/tooltip.svg`)}
                      style={{cursor: 'help'}}
                    />
                  </OverlayTrigger>
                  <div className='text-black fs-1 fs-xxl-1 fw-bolder'>{item.value}</div>
                  <div className='text-gray-600 fw-bold'>{item.text}</div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='dashboard-card dashboard-2fr'>
            <div className='spinner-border text-secondary' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TuSaldoHorizontal
