import axios, { AxiosResponse } from 'axios';
import { IWithdrawalReportPersonal } from 'src/models/History';
import { API_ENDPOINTS, getEndPoint } from './utils/endpoints';

export const sendWithdrawal = async (params: any): Promise<any> => {
    const url: string = getEndPoint(API_ENDPOINTS.WITHDRAWAL.SEND);

    try {
        const api_reponse: AxiosResponse = await axios.post(url, { ...params });
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};

export const reportWithdrawalPersonal = async (
    id: number,
    type: string,
    start: string,
    end: string):
    Promise<IWithdrawalReportPersonal[]> => {
    let url = getEndPoint(API_ENDPOINTS.WITHDRAWAL.REPORT_FILTER, { id, start, end, type });

    try {
        const api_reponse: AxiosResponse = await axios.get(url);
        if (api_reponse.data) {
            return api_reponse.data;
        }
        throw Error(api_reponse.data.errorMessage);
    } catch (e) {
        const typedErr = e as Error;
        throw typedErr;
    }
};