import {useState} from 'react'
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {UserModel} from 'src/app/modules/auth/models/UserModel'
import {RootState} from 'src/setup'
import {shallowEqual, useSelector} from 'react-redux'
import {Transactions} from './Transactions'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const [isAlert, setIsAlert] = useState(false)

  const setAlert = () => {
    setIsAlert(true)
    setTimeout(function () {
      setIsAlert(false)
    }, 2000)
  }

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to=''
        icon='/media/icons/duotune/general/gen007.svg'
        title='Noticias'
        fontIcon='bi-layers'
      /> */}
      {user.first_name ? (
        <Transactions />
      ) : (
        <div onClick={() => setAlert()}>
          <AsideMenuItem
            to='/crafted/account/settings'
            title='Transacciones'
            icon='/media/icons/duotune/general/gen019.svg'
            fontIcon='bi-person'
          />
          {isAlert && (
            <div className='alert alert-warning mx-3' role='alert'>
              Informacion de perfil incompleta
            </div>
          )}
        </div>
      )}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Navegación</span>
        </div>
      </div>
      <AsideMenuItem
        to='/crafted/account/overview'
        title='Perfil'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      />
      {/* <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Perfil'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Ver Perfil' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Configurar perfil' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/account/refered'
        title='Referidos'
        icon='/media/icons/duotune/abstract/abs020.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/account/refered' title='Mis referidos' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Informes'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Personales' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/profile/performance'
            title='Informe de rendimiento'
            hasBullet={true}
          />
          {/* <AsideMenuItem to='#' title='Informe de niveles' hasBullet={true} />
          <AsideMenuItem to='#' title='Conexiones' hasBullet={true} /> */}
          <AsideMenuItem
            to='/crafted/pages/profile/history'
            title='Historial de opeaciones'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItem
          to='/crafted/pages/wizards/bimonthly'
          title='Rendimiento Global'
          hasBullet={true}
        />

        {/* <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Globales' hasBullet={true}>
          <AsideMenuItem to='#' title='Bonus Global' hasBullet={true} />
          <AsideMenuItem to='#' title='Rendimiento bimestral' hasBullet={true} />
        </AsideMenuItemWithSub> */}
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/tools'
        title='Herramientas'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      > <AsideMenuItem to='/crafted/tools/investmentsimulator' title='Simulador de Capital' hasBullet={true} />
        <AsideMenuItem to='/crafted/tools/simulator' title='Simulador de referidos' hasBullet={true} />
        {user.role === 2 && (
          <>
            <AsideMenuItem
              to='/crafted/tools/management'
              title='Módulo de gestión'
              hasBullet={true}
            />
             <AsideMenuItem
              to='/crafted/tools/DataAnalytics'
              title='Analítica de datos'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/crafted/tools/userManagement'
              title='Gestión de usuarios'
              hasBullet={true}
            />
          </>
        )}
      </AsideMenuItemWithSub>
      {/* <AsideMenuItem
        to='/crafted/tutorial'
        title='Tutoriales'
        icon='/media/icons/duotune/general/gen060.svg'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItem
        to='#'
        icon='/media/icons/duotune/coding/cod002.svg'
        title='Academia'
        fontIcon='bi-layers'
      /> */}
    </>
  )
}
